import { uuidToBase32, zColor, zRequiredString } from ':utils/common';
import { zDateTime } from ':utils/dateTime';
import { zId, type Id } from ':utils/id';
import { z } from 'zod';

export enum StripeConnectionState {
    disconnected = 'disconnected',
    detailsRequired = 'detailsRequired',
    verificationRequired = 'verificationRequired',
    connected = 'connected',
}

export type TeamOutput = z.infer<typeof zTeamOutput>;
export const zTeamOutput = z.object({
    id: zId,
    title: z.string(),
    legalName: z.string(),
    email: z.string(),
    stripeConnectionState: z.nativeEnum(StripeConnectionState),
    isPaypalConnected: z.boolean(),
    isEmailsUnsubscribed: z.boolean(),
    isReferralAutomatic: z.boolean(),
    referralPercent: z.number(),
    stiggCustomerId: z.string(),
});

export type TeamEdit = z.infer<typeof zTeamEdit>;
export const zTeamEdit = z.object({
    title: z.string(),
    legalName: z.string(),
    email: z.string(),
}).partial();

export enum TeamMemberRole {
    scheduler = 'scheduler',
    master = 'master',
    freelancer = 'freelancer',
}

export type TeamMemberOutput = z.infer<typeof zTeamMemberOutput>;
export const zTeamMemberOutput = z.object({
    id: zId,
    role: z.nativeEnum(TeamMemberRole),
    appUser: z.object({
        id: zId,
        firstName: z.string(),
        lastName: z.string().optional(),
        email: z.string(),
        phoneNumber: z.string().optional(),
    }),
    color: zColor,
});

export function generatePaymentEmail(team: { id: Id }): string {
    const shortUuid = uuidToBase32(team.id);
    return `p+${shortUuid}@flowlance.com`;
}

export type TeamMemberEdit = z.infer<typeof zTeamMemberEdit>;
export const zTeamMemberEdit = z.object({
    color: zColor,
});

const zTeamReferral = z.object({
    /** we don't want to show the whole team id, so this is just a short part of it */
    teamCode: z.string(),
    createdAt: zDateTime,
    subscription: z.object({
        status: z.string().optional(),
        totalPrice: z.number().optional(),
    }),
    /** The currency is always USD. */
    revenue: z.number(),
});

export type TeamReferrals = z.infer<typeof zTeamReferrals>;
export const zTeamReferrals = z.object({
    visits: z.number(),
    referrals: z.array(zTeamReferral),
    totalEarnings: z.number(),
});

export const zPromoCode = z.object({
    type: zRequiredString(),
    value: zRequiredString(),
});
