import type { StoreBio, StoreOutput, StoreSocial } from ':utils/entity/store';
import type { FileOutput } from ':utils/entity/file';
import { routesFE, routesStore } from ':utils/routes';
import { Button } from ':components/shadcn';
import clsx from 'clsx';
import { linkify } from ':utils/common';
import { EnvelopeIcon, Globe2Icon, GridPlus2Icon, InputPasswordEditIcon, Pen2Icon, PlusIcon, Video1Icon } from ':components/icons/basic';
import { socialIcons } from ':components/icons/logos';
import type { IconType } from ':components/icons/common';
import { useTranslation } from 'react-i18next';

export type StoreBioAction = {
    type: 'info' | 'image' | 'video';
} | {
    type: 'socials';
    /** If undefined, a new social should be created. */
    index: number | undefined;
};

export const STORE_THUMBNAIL_CLASS = 'max-h-40 size-40 rounded-full bg-white border-2 border-primary shadow-[0px_15px_35px_0px_rgba(85,12,230,0.15)]';
// Two times the desired size - just to be sure.
export const STORE_IMAGE_MAX_SIZE = 40 * 4 * 2;

type StoreBioDisplayProps = Readonly<{
    bio: StoreBio;
    image: FileOutput | undefined;
    onClick?: (action: StoreBioAction) => void;
}>;

export function StoreBioDisplay({ bio, image, onClick }: StoreBioDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'storeBioDisplay' });

    const src = image
        ? routesFE.files.uploads(image.hashName)
        : routesFE.files.static('flowlance-icon.svg');

    return (
        <div className='flex flex-col items-center gap-5'>
            <div className='flex flex-col items-center relative'>
                <img src={src} className={STORE_THUMBNAIL_CLASS} />
                {onClick && (
                    <div className='absolute left-[130px] top-[10px]'>
                        <EditButton
                            label={t('edit-image-button')}
                            onClick={() => onClick({ type: 'image' })}
                        />
                    </div>
                )}
                {onClick ? (
                    <Button variant='outline' size='small' className='rounded-full -mt-4' onClick={() => onClick({ type: 'video' })}>
                        <Video1Icon />{t(bio.videoUrl ? 'edit-video-button' : 'add-video-button')}
                    </Button>
                ) : (bio.videoUrl && (
                    <a href={linkify(bio.videoUrl)} target='_blank' rel='noopener' className='-mt-4'>
                        <Button variant='outline' size='small' className='rounded-full'>
                            <Video1Icon />{t('play-video-button')}
                        </Button>
                    </a>
                ))}
            </div>
            <div className='flex flex-col items-center gap-2'>
                <div className='flex items-center fl-store-text-base'>
                    <span className='font-semibold leading-none text-center fl-store-text-highlight text-2xl md:text-4xl'>
                        {bio.name}
                    </span>
                    {onClick && (
                        <EditButton
                            isTransparent
                            label={t('edit-info-button')}
                            onClick={() => onClick({ type: 'info' })}
                        />
                    )}
                </div>
                {bio.headline && (
                    <span className='fl-store-text-base'>{bio.headline}</span>
                )}
                {bio.description && (
                    <div className='pt-1 text-center whitespace-pre-line max-w-[400px] fl-store-text-base leading-5'>
                        {bio.description}
                    </div>
                )}
            </div>
            {onClick && (!bio.description || bio.socials.length === 0) && (
                <div className={clsx('flex flex-wrap justify-center gap-2', !bio.description && '-mt-2')}>
                    {!bio.description && (
                        <Button
                            variant='outline'
                            size='small'
                            className='rounded-full min-h-0'
                            onClick={() => onClick({ type: 'info' })}
                        >
                            <InputPasswordEditIcon />{t('add-description-button')}
                        </Button>
                    )}
                    {bio.socials.length === 0 && (
                        <Button
                            variant='outline'
                            size='small'
                            className='rounded-full min-h-0'
                            onClick={() => onClick({ type: 'socials', index: undefined })}
                        >
                            <GridPlus2Icon />{t('add-socials-button')}
                        </Button>
                    )}
                </div>
            )}
            {bio.socials.length > 0 && (
                <div className='flex flex-wrap items-center justify-center gap-1'>
                    {bio.socials.map((social, index) => (
                        <SocialButton key={index} social={social} onClick={onClick && (() => onClick({ type: 'socials', index }))} />
                    ))}
                    {onClick && (
                        <Button
                            aria-label={t('add-socials-button')}
                            variant='transparent'
                            size='exact'
                            className='fl-store-text-base size-10 [&_svg]:size-5'
                            onClick={() => onClick({ type: 'socials', index: undefined })}
                        >
                            <PlusIcon />
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

type EditButtonProps = Readonly<{
    label: string;
    onClick: () => void;
    isTransparent?: boolean;
}>;

function EditButton({ label, onClick, isTransparent }: EditButtonProps) {
    return (
        <div className='relative size-0'>
            {isTransparent ? (
                <Button aria-label={label} variant='transparent' size='exact' className='absolute -top-4 size-8 fl-store-text-base' onClick={onClick}>
                    <Pen2Icon size='sm' />
                </Button>
            ) : (
                <Button aria-label={label} variant='outline' size='tiny' className='size-8' onClick={onClick}>
                    <Pen2Icon size='sm' />
                </Button>
            )}
        </div>
    );
}

type SocialButtonProps = Readonly<{
    social: StoreSocial;
    /** If not provided, a link will be created instead. */
    onClick: (() => void) | undefined;
}>;

function SocialButton({ social, onClick }: SocialButtonProps) {
    const icon = social.type === otherSocialIconId
        ? <Globe2Icon size={20} className='text-secondary-800'/>
        : socialIconsWithLabels[social.type].icon({ size: 20 });

    if (onClick) {
        return (
            <Button variant='outline' size='exact' className='size-10 [&_svg]:size-5' onClick={onClick} aria-label={social.title}>
                {icon}
            </Button>
        );
    }

    // it may happen that there's non-normalized url in the database
    const url = social.type === 'email' ? `mailto:${social.url}` : linkify(normalizeSocialUrl(social.url, social.type));

    return (
        <a href={url} target='_blank' rel='noopener' aria-label={social.title}>
            <Button variant='outline' size='exact' className='size-10 [&_svg]:size-5'>
                {icon}
            </Button>
        </a>
    );
}

type SocialIconWithLabel = {
    icon: IconType;
    label: string;
};

// Don't edit the keys of this object - they are used in the database.
// Use only lowercase letters (for consistency).
export const socialIconsWithLabels: Record<string, SocialIconWithLabel> = {
    facebook: { icon: socialIcons.FacebookIcon, label: 'Facebook' },
    instagram: { icon: socialIcons.InstagramIcon, label: 'Instagram' },
    twitter: { icon: socialIcons.TwitterIcon, label: 'X' },
    linkedin: { icon: socialIcons.LinkedInIcon, label: 'LinkedIn' },
    youtube: { icon: socialIcons.YouTubeIcon, label: 'YouTube' },
    pinterest: { icon: socialIcons.PinterestIcon, label: 'Pinterest' },
    medium: { icon: socialIcons.MediumIcon, label: 'Medium' },
    threads: { icon: socialIcons.ThreadsIcon, label: 'Threads' },
    whatsapp: { icon: socialIcons.WhatsAppIcon, label: 'WhatsApp' },
    tiktok: { icon: socialIcons.TikTokIcon, label: 'TikTok' },
    telegram: { icon: socialIcons.TelegramIcon, label: 'Telegram' },
    reddit: { icon: socialIcons.RedditIcon, label: 'Reddit' },
    slack: { icon: socialIcons.SlackIcon, label: 'Slack' },
    discord: { icon: socialIcons.DiscordIcon, label: 'Discord' },
    substack: { icon: socialIcons.SubstackIcon, label: 'Substack' },
    snapchat: { icon: socialIcons.SnapchatIcon, label: 'Snapchat' },
    email: { icon: EnvelopeIcon, label: 'Email' },
};

export const otherSocialIconId = 'other';

export function normalizeSocialUrl(input: string, type: string) {
    input = input.trim();

    if (!socialNormalizers[type])
        return input;

    const { except, replace, custom } = socialNormalizers[type];
    if (Array.isArray(except) ? except.some(e => input.includes(e)) : input.includes(except))
        return input;

    for (const rule of custom ?? []) {
        if (input.includes(rule.pattern))
            return doReplace(input, rule.replace);
    }

    return doReplace(input, replace);
}

function doReplace(input: string, replace: ReplaceFunction): string {
    return typeof replace === 'function'
        ? replace(input)
        : (replace +input);
}

/** If string, the input is just added to its end. */
type ReplaceFunction = string | ((input: string) => string);

type NormalizerDefinition = {
    /** If matched, the original input is returned. */
    except: string | string[];
    /** Called if nothing matched. */
    replace: ReplaceFunction;
    custom?: {
        /** If matched, the custom replace function is called. */
        pattern: string;
        replace: ReplaceFunction;
    }[];
};

const socialNormalizers: Record<string, NormalizerDefinition> = {
    facebook: { except: 'facebook.com', replace: 'https://www.facebook.com/' },
    instagram: { except: 'instagram.com', replace: 'https://www.instagram.com/' },
    twitter: { except: [ 'x.com', 'twitter.com' ], replace: 'https://www.twitter.com/' },
    linkedin: { except: 'linkedin.com', replace: 'https://www.linkedin.com/in/' },
    youtube: {
        except: 'youtube.com',
        custom: [ { pattern: '@', replace: 'https://www.youtube.com/' } ],
        replace: 'https://www.youtube.com/@',
    },
    pinterest: { except: 'pinterest.com', replace: 'https://www.pinterest.com/' },
    medium: { except: 'medium.com', replace: 'https://www.medium.com/' },
    threads: {
        except: 'threads.net',
        custom: [ { pattern: '@', replace: 'https://www.threads.net/' } ],
        replace: 'https://www.threads.net/@',
    },
    whatsapp: { except: 'whatsapp.com', replace: 'https://wa.me/' },
    tiktok: {
        except: 'tiktok.com',
        custom: [ { pattern: '@', replace: 'https://www.tiktok.com/@' } ],
        replace: 'https://www.tiktok.com/@',
    },
    telegram: { except: 't.me', replace: 'https://t.me/' },
    reddit: { except: 'reddit.com', replace: 'https://www.reddit.com/user/' },
    // TODO
    // slack: { except: '', replace: '' },
    discord: { except: 'discord.gg', replace: 'https://discord.gg/' },
    // substack: { except: '', replace: '' },
    // snapchat: { except: '', replace: '' },
};

type FreelancerBadgeProps = Readonly<{
    store: StoreOutput;
}>;

export function FreelancerBadge({ store }: FreelancerBadgeProps) {
    const src = store.image
        ? routesFE.files.uploads(store.image.hashName)
        : routesFE.files.static('flowlance-icon.svg');

    return (
        <a className='w-fit flex items-center gap-2 fl-store-text-highlight' href={routesStore.store.absoluteResolve(store)}>
            <img src={src} className='max-h-10 size-10 rounded-full bg-white' />

            {store.bio.name}
        </a>
    );
}
