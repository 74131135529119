import { Button, Form, Modal, Skeleton, SpinnerButton } from ':components/shadcn';
import { trpc } from ':frontend/context/TrpcProvider';
import type { StoreOutput } from ':utils/entity/store';
import { useId, useState, type Dispatch } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { BulletFeatures } from './integrationUtils';
import { useCached } from ':components/hooks';
import { createErrorAlert } from '../notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { Trash2Icon } from ':components/icons/basic';
import { useEntitlement } from ':frontend/lib/stigg';
import { StiggFeature } from ':utils/lib/stigg';
import { UpsellButton } from ':frontend/components/team/subscription';

export function MetaPixelIntegration() {
    const store = trpc.store.getStore.useQuery().data;
    if (!store)
        return <Skeleton height={136} />;

    return <MetaPixelIntegrationLoaded store={store} />;
}

function MetaPixelIntegrationLoaded({ store }: { store: StoreOutput }) {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations.metaPixel' });
    const isEnabled = useEntitlement(StiggFeature.MetaPixel);
    const [ modalInput, setModalInput ] = useState<ModalInput>();

    function openModal() {
        setModalInput({ metaId: store.metaPixelId });
    }

    const isConnected = !!store.metaPixelId;

    return (
        <div className='border rounded-2xl p-5 max-sm:space-y-4 sm:flex sm:items-start sm:gap-4'>
            <Modal.Root open={!!modalInput} onOpenChange={open => !open && setModalInput(undefined)}>
                <Modal.Content closeButton={t('close-button')}>
                    <MetaPixelModalInner input={modalInput} setInput={setModalInput} />
                </Modal.Content>
            </Modal.Root>

            <img className='h-8 w-auto' src='/static/integration-icons/facebook.svg' />

            <div className='space-y-2'>
                <h2 className='text-secondary-900'>{t('product')}</h2>

                <div>
                    <BulletFeatures text={t('features')} isActive={isConnected} />
                </div>
            </div>

            <div className='grow self-stretch flex items-center justify-end'>
                {!isEnabled ? (
                    <UpsellButton feature={StiggFeature.MetaPixel} />
                ) : isConnected ? (
                    <div className='max-sm:w-full flex flex-col gap-2'>
                        <a href={PIXEL_CONNECTED_URL} target='_blank' rel='noreferrer'>
                            <Button variant='ghost' size='small' className='max-sm:w-full'>
                                {t('connected-link')}
                            </Button>
                        </a>

                        <Button
                            onClick={openModal}
                            variant='outline'
                            size='small'
                            className='max-sm:w-full'
                        >
                            {t('edit-button')}
                        </Button>
                    </div>
                ) : (
                    <Button
                        onClick={openModal}
                        size='small'
                        className='max-sm:w-full'
                    >
                        {t('connect-button')}
                    </Button>
                )}
            </div>
        </div>
    );
}

const PIXEL_START_URL = 'https://www.facebook.com/events_manager2/';
const PIXEL_DOCS_URL = 'https://www.facebook.com/business/help/952192354843755';
const PIXEL_CONNECTED_URL = 'https://www.facebook.com/events_manager2/list/';

type ModalInput = {
    metaId: string | undefined;
};

/** We do want to reset the modal state when closed, so we use a separate component for it. */
function MetaPixelModalInner({ input, setInput }: { input: ModalInput | undefined, setInput: Dispatch<ModalInput | undefined> }) {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations.metaPixel' });
    const [ metaId, setMetaId ] = useState(input?.metaId ?? '');
    const [ isSubmitted, setIsSubmitted ] = useState(false);
    const [ isError, setIsError ] = useState(false);
    const [ fetching, setFetching ] = useState<string>();

    function inputMetaId(value: string) {
        const newMetaId = extractMetaIdFromInput(value);
        setMetaId(newMetaId);
        if (isSubmitted)
            setIsError(!isMetaIdValid(newMetaId));
    }

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();
    const { addAlert } = useNotifications();

    function save() {
        if (!isMetaIdValid(metaId)) {
            setIsError(true);
            setIsSubmitted(true);
            return;
        }

        setFetching(FID_SAVE);
        updateStore(metaId);
    }

    function disconnect() {
        setFetching(FID_DISCONNECT);
        updateStore(null);
    }

    function updateStore(metaPixelId: string | null) {
        updateStoreMutation.mutate({ metaPixelId }, {
            onSuccess: data => {
                utils.store.getStore.setData(undefined, data);
                setInput(undefined);
            },
            onError: error => {
                addAlert(createErrorAlert(error));
            },
        });
    }

    const descriptionId = useId();

    const cachedInput = useCached(input);
    const isConnected = cachedInput?.metaId;

    return (<>
        <Modal.Header>
            <Modal.Title>
                {t(`modal-title-${isConnected ? 'edit' : 'connect'}`)}
            </Modal.Title>
        </Modal.Header>

        <div>
            <Form.Input
                label={t('metaId-label')}
                aria-describedby={descriptionId}
                placeholder={t('metaId-placeholder')}
                value={metaId}
                onChange={e => inputMetaId(e.target.value)}
            />

            {isError && (
                <ErrorMessage message={t('metaId-error')} />
            )}

            <Form.Description id={descriptionId} className='mt-2'>
                <Trans
                    t={t}
                    i18nKey='metaId-description'
                    components={{
                        a1: <a href={PIXEL_START_URL} target='_blank' rel='noreferrer' className='underline text-secondary-500' />,
                        a2: <a href={PIXEL_DOCS_URL} target='_blank' rel='noreferrer' className='underline text-secondary-500' />,
                        s: <span className='text-secondary-500' />,
                    }}
                />
            </Form.Description>
        </div>

        <Modal.Footer className='pt-4 flex-col gap-4'>
            <SpinnerButton size='small' className='w-full' fetching={fetching} fid={FID_SAVE} onClick={save} disabled={metaId === cachedInput?.metaId}>
                {t('save-button')}
            </SpinnerButton>

            {isConnected && (
                <SpinnerButton variant='danger' size='small' className='w-full' fetching={fetching} fid={FID_DISCONNECT} onClick={disconnect}>
                    <Trash2Icon />
                    {t('disconnect-button')}
                </SpinnerButton>
            )}
        </Modal.Footer>
    </>);
}

const FID_SAVE = 'save';
const FID_DISCONNECT = 'disconnect';

// Marian has found IDs of length 15 and 16, leaving some space for error.
const metaIdRegex = /^[0-9]{12,20}$/;

function isMetaIdValid(metaId: string) {
    return metaId.match(metaIdRegex);
}

const extractionRegex = /fbq\('init', '([0-9]{12,20})'\)/;

function extractMetaIdFromInput(input: string) {
    // If the user copy-pastes the whole script tag, we try to extract the ID from it.
    const match = input.match(extractionRegex);
    // Otherwise, we just return the input as is, because it's probably the ID itself.
    return match?.[1] ?? input;
}
