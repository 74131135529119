import { useState, type SetStateAction, type Dispatch, useId } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowLeftIcon, Trash2Icon } from ':components/icons/basic';
import { Form, Button, SpinnerButton, Modal } from ':components/shadcn';
import { trpc } from ':frontend/context/TrpcProvider';
import { env } from ':env';
import type { StoreOutput } from ':utils/entity/store';
import { ErrorMessage } from '../forms/ErrorMessage';
import { Table } from '../common/Table';
import { useToggle } from ':frontend/hooks';
import { routesStore, routeToDisplayString } from ':utils/routes';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert } from '../notifications';
import { StiggFeature } from ':utils/lib/stigg';
import { useEntitlement } from ':frontend/lib/stigg';
import { UpsellButton } from ':frontend/components/team/subscription';

export function CustomDomain({ store }: { store: StoreOutput }) {
    const [ state, setState ] = useState<FormState>(() => computeInitialState(store.domain));
    const isEnabled = useEntitlement(StiggFeature.CustomDomain);

    if (!isEnabled) {
        return (
            <div className='grow flex justify-end'>
                <UpsellButton feature={StiggFeature.CustomDomain} />
            </div>
        );
    }

    switch (state.phase) {
    case FormPhase.input:
        return <InputPhaseTab state={state} setState={setState} />;
    case FormPhase.dns:
        return <DnsPhaseTab state={state} setState={setState} />;
    case FormPhase.success:
        return <SuccessPhaseTab state={state} setState={setState} store={store} />;
    }
}

function computeInitialState(currentDomain: string): FormState {
    return currentDomain === env.VITE_DEFAULT_STORE_DOMAIN
        ? { domain: '', phase: FormPhase.input }
        : { domain: currentDomain, phase: FormPhase.success };
}

enum FormPhase {
    input = 'input',
    dns = 'dns',
    success = 'success',
}

type FormState = {
    domain: string;
    phase: FormPhase;
};

type StateSetProps = {
    state: FormState;
    setState: Dispatch<SetStateAction<FormState>>;
};

function InputPhaseTab({ state, setState }: StateSetProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general.customDomain' });
    const [ isSubmitted, setIsSubmitted ] = useState(false);
    const [ isError, setIsError ] = useState(false);

    function input(domain: string) {
        setState(prev => ({ ...prev, domain }));
        if (isSubmitted) {
            const finalDomain = extractDomainFromInput(domain);
            setIsError(!isDomainValid(finalDomain));
        }
    }

    function next() {
        const finalDomain = extractDomainFromInput(state.domain);
        if (!isDomainValid(finalDomain)) {
            setIsError(true);
            setIsSubmitted(true);
            return;
        }

        setState(prev => ({ ...prev, phase: FormPhase.dns }));
    }

    const descriptionId = useId();

    return (
        <div>
            <div>
                <Form.Input
                    label={t('input-label')}
                    // TODO customize the placeholder based on the user's name/email/slug
                    placeholder={t('input-placeholder')}
                    value={state.domain}
                    onChange={e => input(e.target.value)}
                    isError={isError}
                    aria-describedby={descriptionId}
                />

                {isError && (
                    <ErrorMessage message={t('input-error')} />
                )}

                <Form.Description id={descriptionId} className='mt-2'>
                    {t('input-description')}
                </Form.Description>
            </div>

            <Button size='small' className='mt-8 w-full' onClick={next}>
                {t('next-button')}
            </Button>
        </div>
    );
}

const domainRegex = /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

function isDomainValid(domain: string) {
    return domainRegex.test(domain);
}

function extractDomainFromInput(input: string) {
    return input.trim().replace(/^https?:\/\//, '');
}

const TUTORIAL_URL = 'https://flowlance.notion.site/How-to-connect-your-custom-domain-to-Flowlance-1bae1bd09b2580c4bf35e5eed7ce7633';

function DnsPhaseTab({ state, setState }: StateSetProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general.customDomain' });
    const finalDomain = extractDomainFromInput(state.domain);
    const dnsRecords = getDnsRecords(finalDomain);

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();
    const { addAlert } = useNotifications();

    function updateDomain() {
        updateStoreMutation.mutate({ domain: finalDomain }, {
            onSuccess: data => {
                setState({ domain: finalDomain, phase: FormPhase.success });
                utils.store.getStore.setData(undefined, data);
            },
            onError: error => {
                // TODO handle error
                addAlert(createErrorAlert(error));
            },
        });
    }

    return (
        <div className='space-y-4'>
            <p className='whitespace-pre-line'>
                <Trans
                    t={t}
                    i18nKey='dns-description'
                    components={{
                        tutorialLink: <a href={TUTORIAL_URL} target='_blank' rel='noopener' className='text-primary' />,
                    }}
                    count={dnsRecords.length}
                />
            </p>

            <div className='fl-hide-scrollbar overflow-x-auto'>
                <Table>
                    <Table.Header>
                        <Table.Col>{t('type-label')}</Table.Col>
                        <Table.Col>{t('name-label')}</Table.Col>
                        <Table.Col>{t('value-label')}</Table.Col>
                        <Table.Col>{t('ttl-label')}</Table.Col>
                    </Table.Header>

                    <Table.Body>
                        {dnsRecords.map(record => (
                            <Table.Row key={record.type}>
                                <Table.Col>{record.type}</Table.Col>
                                <Table.Col>{record.name}</Table.Col>
                                <Table.Col>{record.value}</Table.Col>
                                <Table.Col>{TTL_SECONDS}</Table.Col>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>

            <div className='pt-4 flex max-sm:flex-col justify-between gap-2'>
                <Button size='small' variant='outline' onClick={() => setState(prev => ({ ...prev, phase: FormPhase.input }))}>
                    <ArrowLeftIcon />
                    {t('prev-button')}
                </Button>

                <SpinnerButton size='small' isFetching={updateStoreMutation.isPending} onClick={updateDomain}>
                    {t('save-button', { count: dnsRecords.length })}
                </SpinnerButton>
            </div>
        </div>
    );
}

/** Just so that our customers aren't confusion. */
const TTL_SECONDS = 3600;

function getDnsRecords(domain: string) {
    const domainType = getDomainType(domain);

    if (domainType === 'apex') {
        return [
            { type: 'A', name: '@', value: env.VITE_DNS_VALUES.A },
            ...(env.VITE_DNS_VALUES.AAAA ? [ { type: 'AAAA', name: '@', value: env.VITE_DNS_VALUES.AAAA } ] : []),
        ];
    }
    else if (domainType === 'subdomain') {
        const subdomain = domain.split('.').slice(0, -2).join('.');
        return [
            { type: 'CNAME', name: subdomain, value: env.VITE_DNS_VALUES.CNAME },
        ];
    }

    throw new Error('Invalid domain type');
}

function getDomainType(input: string) {
    // this is not very correct, in countries like the UK, an apex domain can end with .co.uk
    const dotCount = input.split('.').length - 1;
    if (dotCount === 0)
        return 'invalid' as const;
    else if (dotCount === 1)
        return 'apex' as const;
    else
        return 'subdomain' as const;
}

function SuccessPhaseTab({ setState, store }: StateSetProps & { store: StoreOutput }) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general.customDomain' });
    const [ disconnectModalOpen, setDisconnectModalOpen ] = useToggle(false);

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();
    const { addAlert } = useNotifications();

    function disconnectDomain() {
        const domain = env.VITE_DEFAULT_STORE_DOMAIN;

        updateStoreMutation.mutate({ domain }, {
            onSuccess: data => {
                utils.store.getStore.setData(undefined, data);
                setState(computeInitialState(data.domain));
                setDisconnectModalOpen.false();
            },
            onError: error => {
                // TODO handle error
                addAlert(createErrorAlert(error));
            },
        });
    }

    const currentUrl = routesStore.store.absoluteResolve(store);

    return (
        <div className='space-y-4'>
            <DisconnectModal store={store} open={disconnectModalOpen} onClose={setDisconnectModalOpen.false} onConfirm={disconnectDomain} />

            <div className='flex flex-col items-center gap-4'>
                <div className='text-2xl/6 font-semibold text-secondary-700'>{t('success-title')}</div>

                <p className='text-center whitespace-pre-line'>
                    <Trans
                        t={t}
                        i18nKey='success-text'
                        components={{
                            link: (
                                <a href={currentUrl} target='_blank' rel='noopener' className='text-primary'>
                                    {routeToDisplayString(currentUrl)}
                                </a>
                            ),
                        }}
                    />
                </p>
            </div>

            <p className='text-secondary-300'>{t('success-warning')}</p>

            <div className='pt-4 flex max-sm:flex-col justify-between gap-2'>
                <Button size='small' variant='outline' onClick={() => setState(prev => ({ ...prev, phase: FormPhase.input }))}>
                    <ArrowLeftIcon />
                    {t('edit-button')}
                </Button>

                <Button size='small' variant='danger' onClick={setDisconnectModalOpen.true}>
                    <Trash2Icon />
                    {t('disconnect-button')}
                </Button>
            </div>
        </div>
    );
}

function DisconnectModal({ store, open, onClose, onConfirm }: { store: StoreOutput, open: boolean, onClose: () => void, onConfirm: () => void }) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general.customDomain' });
    const [ isFetching, setIsFetching ] = useToggle(false);

    function confirm() {
        setIsFetching.true();
        onConfirm();
    }

    const defaultStoreUrl = routeToDisplayString(routesStore.store.absoluteResolve({ domain: env.VITE_DEFAULT_STORE_DOMAIN, slug: store.slug }));

    return (
        <Modal.Root
            open={open}
            onOpenChange={onClose}
        >
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='space-y-4'>
                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('disconnect-title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='space-y-2 text-center'>
                    <div className='leading-5 max-w-sm mx-auto text-center'>
                        {t('disconnect-text', { storeUrl: store.domain, defaultStoreUrl })}
                    </div>
                </div>

                <Modal.Footer className='grid sm:grid-cols-2 max-sm:justify-normal gap-4'>
                    <Button size='small' variant='secondary' onClick={onClose}>
                        {t('cancel-button')}
                    </Button>

                    <SpinnerButton size='small' isFetching={isFetching} variant='primary' onClick={confirm}>
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}
