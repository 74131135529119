import { zId } from ':utils/id';
import { z } from 'zod';

/** High-level file type. Not a MIME type! */
export enum FileClass {
    Image = 'image',
    // Others might be added later.
}

export const fileClassToTypes: Record<FileClass, string[]> = {
    [FileClass.Image]: [ 'image/png', 'image/jpeg', 'image/svg+xml' ],
};

export type FileOutput = z.infer<typeof zFileOutput>;
export const zFileOutput = z.object({
    id: zId,
    originalName: z.string(),
    hashName: z.string(),
    type: z.string(),
    size: z.number(),
});

export type FileUpsert = z.infer<typeof zFileUpsert>;
export const zFileUpsert = z.union([
    z.object({
        name: z.string(),
        type: z.string(),
        /** base64-encoded, see {@link dataUrlToServer} */
        data: z.string(),
    }),
    z.object({
        id: zId,
    }),
]);

export type FileData = {
    dataUrl: string;
    name: string;
    type: string;
    sizeMB: number;
};

/**
 * Transforms a File object (as returned by FileList[0] as returned by <input type="file" />) to *dataUrl* and some other data (name, type).
 * @see https://developer.mozilla.org/en-US/docs/Web/URI/Schemes/data
 */
export async function fileToFileData(input: File): Promise<FileData> {
    const dataUrl = await fileToDataUrl(input);
    return ({
        dataUrl,
        name: input.name,
        type: input.type,
        // The original size is in bytes. One megabyte (MB) is exactly 1e6 bytes (we are using the SI units).
        sizeMB: input.size / 1e6,
    });
}

/**
 * Extracts *dataUrl* from the File object.
 */
function fileToDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result)
                resolve(reader.result.toString());
            else
                reject(null);
        };

        reader.onerror = error => reject(error);

        reader.readAsDataURL(file);
    });
}

export function fileDataToServer(input: FileData): FileUpsert {
    return {
        data: dataUrlToServer(input.dataUrl),
        name: input.name,
        type: input.type,
    };
}

/**
 * Returns a plain base64 without any data (URL stuff, MIME type, ...), padded with '=' to the correct length.
 */
function dataUrlToServer(input: string): string {
    let encoded = input.replace(/^data:(.*,)?/, '');
    if ((encoded.length % 4) > 0)
        encoded += '='.repeat(4 - (encoded.length % 4));

    return encoded;
}
