import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Modal, SpinnerButton } from ':components/shadcn';
import { useMaster } from ':frontend/context/UserProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { Currency, type CurrencyId } from ':utils/money';
import { createErrorAlert, createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { paymentIcons } from ':components/icons/logos';
import { UpsertBankAccountModal } from ':frontend/components/settings/UpsertBankAccountModal';
import { MoneyBillsDollarIcon, BankIcon, ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon, Trash2Icon, Sliders3Icon, LockIcon } from ':components/icons/basic';
import { useToggle } from ':frontend/hooks';
import { PaymentsPreferencesForm } from ':frontend/components/settings/PreferencesForm';
import { StripeConnectionState } from ':utils/entity/team';
import { PaymentMethod, type BankAccountOutput } from ':utils/entity/invoicing';
import { ErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { CountryNotSupportedError } from ':utils/error/stripe.error';
import { isNewTypedError } from ':frontend/types/TypedError';
import { env } from ':env';
import { PaymentMethodSelect } from ':frontend/components/forms/PaymentMethodSelect';

export const STRIPE_DASHBOARD_LINK = 'https://dashboard.stripe.com/';
export const PAYPAL_DASHBOARD_LINK = 'https://paypal.com/';

export function PaymentsSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.payments' });
    const { t: tm } = useTranslation('components', { keyPrefix: 'paymentMethodSelect' });
    const { addAlert } = useNotifications();

    const { team, teamSettings, setTeamSettings } = useMaster();
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        // TODO what does this mean?
        if (searchParams.get('c') !== '1')
            return;

        navigate(routesFE.payments.path);
    }, []);

    const updateTeamPreferencesMutation = trpc.team.updateTeamPreferences.useMutation();

    async function changeDefaultPaymentMethod(method: PaymentMethod) {
        if (method !== PaymentMethod.stripe && method !== PaymentMethod.paypal)
            return;
        const newSettings = await updateTeamPreferencesMutation.mutateAsync({ defaultPaymentMethod: method });
        setTeamSettings(newSettings);
        addAlert(createTranslatedSuccessAlert('pages:settings.payments.default-payment-method-changed-alert', { values: { method: tm(`${method}-title`) } }));
    }

    const bothMethodsConnected = team.stripeConnectionState === StripeConnectionState.connected && team.isPaypalConnected;

    return (
        <div className='p-4 py-8 md:py-12 space-y-4 md:space-y-8'>
            <Card>
                <Card.Title className='flex items-center gap-2'>
                    <MoneyBillsDollarIcon className='h-5 text-warning-500' />
                    <span>{t('payout-section')}</span>
                </Card.Title>

                <Card.Divider />

                <div className='space-y-4'>
                    <div className='max-sm:space-y-4 sm:flex sm:items-center sm:gap-4 border rounded-lg p-5' >
                        <div className='flex items-center'>
                            <paymentIcons.StripeIcon className='w-17 h-8' />
                        </div>

                        <div className='flex-1 self-center space-y-2'>
                            <p className='text-secondary-900 text-lg'>Stripe</p>
                            <p className='text-sm text-secondary-400'>{t('stripe-text')}</p>
                            {(team.stripeConnectionState === StripeConnectionState.detailsRequired || team.stripeConnectionState === StripeConnectionState.verificationRequired) && (
                                <ErrorMessage isOnlyWarning message={t('stripe-error')} />
                            )}
                        </div>

                        <StripeIntegration />
                    </div>

                    <div className='max-sm:space-y-4 sm:flex sm:items-center sm:gap-4 border rounded-lg p-5' >
                        <div>
                            <paymentIcons.PayPalIcon className='w-17 h-8' />
                        </div>

                        <div className='flex-1 self-center space-y-2'>
                            <p className='text-secondary-900 text-lg'>PayPal</p>
                            <p className='text-sm text-secondary-400'>{t('paypal-text')}</p>
                        </div>

                        {env.VITE_DISABLE_PAYPAL ? (
                            <div className='inline-flex items-center justify-center gap-2 text-[#0C3FE6] bg-[#EBF3FF] px-5 h-9 rounded-full select-none'>
                                <LockIcon />
                                <span>{t('coming-soon')}</span>
                            </div>
                        ) : (
                            <PaypalIntegration />
                        )}
                    </div>
                </div>

                {!env.VITE_DISABLE_PAYPAL && bothMethodsConnected && (
                    <div className='flex flex-col gap-2 mt-4'>
                        <h2 className='text-lg text-secondary-700'>{t('default-payment-method-title')}</h2>
                        <p>{t('default-payment-method-description')}</p>
                        <PaymentMethodSelect
                            value={teamSettings.defaultPaymentMethod}
                            options={[ PaymentMethod.stripe, PaymentMethod.paypal ]}
                            onChange={changeDefaultPaymentMethod}
                        />
                    </div>
                )}
            </Card>

            <BankAccountsSettings />

            <PreferencesSettings />
        </div>
    );
}

function StripeIntegration() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.stripeIntegration' });
    const { team } = useMaster();
    const { addAlert } = useNotifications();

    // The redirect takes a while, so we need to show a spinner while redirecting.
    const [ isRedirecting, setIsRedirecting ] = useState(false);

    const connectStripeMutation = trpc.team.connectStripe.useMutation({
        onError: error => {
            if (isNewTypedError(error.data, CountryNotSupportedError.type))
                addAlert(createTranslatedErrorAlert('pages:payments.stripeIntegration.country-not-supported-alert'));
            else
                addAlert(createErrorAlert(error.data));
        },
        onSuccess: response => {
            setIsRedirecting(true);
            window.location.href = response.onboardingUrl;
        },
    });

    switch (team.stripeConnectionState) {
    case StripeConnectionState.connected:
        return (
            <a href={STRIPE_DASHBOARD_LINK} target='_blank' rel='noreferrer'>
                <Button variant='primary' size='small'>{t('open-stripe-button')}</Button>
            </a>
        );
    case StripeConnectionState.disconnected:
    case StripeConnectionState.detailsRequired:
        return (
            <SpinnerButton
                isFetching={connectStripeMutation.isPending || isRedirecting}
                onClick={() => connectStripeMutation.mutate()}
                size='small'
            >
                {t('connect-stripe-button')}
            </SpinnerButton>
        );
    case StripeConnectionState.verificationRequired:
        return (
            <SpinnerButton
                isFetching={connectStripeMutation.isPending || isRedirecting}
                onClick={() => connectStripeMutation.mutate()}
                size='small'
            >
                {t('verify-stripe-button')}
            </SpinnerButton>
        );
    }
}

function PaypalIntegration() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.paypalIntegration' });
    const { team } = useMaster();
    const { addAlert } = useNotifications();

    // The redirect takes a while, so we need to show a spinner while redirecting.
    const [ isRedirecting, setIsRedirecting ] = useState(false);

    const connectPaypalMutation = trpc.team.connectPaypal.useMutation({
        onError: error => {
            addAlert(createErrorAlert(error.data));
        },
        onSuccess: response => {
            setIsRedirecting(true);
            window.location.href = response.continueUrl;
        },
    });

    if (team.isPaypalConnected) {
        return (
            <a href={PAYPAL_DASHBOARD_LINK} target='_blank' rel='noreferrer'>
                <Button variant='primary' size='small'>{t('open-paypal-button')}</Button>
            </a>
        );
    }

    return (
        <SpinnerButton
            isFetching={connectPaypalMutation.isPending || isRedirecting}
            onClick={() => connectPaypalMutation.mutate()}
            size='small'
        >
            {t('connect-paypal-button')}
        </SpinnerButton>
    );
}

function BankAccountsSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments' });

    const { addAlert } = useNotifications();
    const { bankAccounts, setBankAccounts } = useMaster();

    const [ showBankAccounts, setShowBankAccounts ] = useState(false);
    const [ deletingAccount, setDeletingAccount ] = useState<BankAccountOutput>();

    const [ showUpsertForm, setShowUpsertForm ] = useState(false);
    const [ editingBankAccount, setEditingBankAccount ] = useState<BankAccountOutput>();

    function deleteAccount(account: BankAccountOutput) {
        setBankAccounts(bankAccounts.filter(a => a.id !== account.id));
        setDeletingAccount(undefined);

        addAlert(createTranslatedSuccessAlert('common:bankAccount.deleted-alert'));
    }

    return (
        <Card>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div onClick={() => setShowBankAccounts(!showBankAccounts)}>
                        <Card.Title className='flex items-center gap-2 cursor-pointer'>
                            <BankIcon className='h-5 text-warning-500 shrink-0' />
                            <span>{t('bank-accounts-title')}</span>
                            <span className='text-secondary'>{t('bank-accounts-description')}</span>
                        </Card.Title>
                    </div>

                    <div className='flex items-center'>
                        <Button
                            variant='outline'
                            size='small'
                            onClick={() => {
                                setEditingBankAccount(undefined);
                                setShowUpsertForm(true);
                            }}
                            className='max-sm:hidden'
                        >
                            {t('create-new-account-label')}
                        </Button>

                        <Button variant='transparent' onClick={() => setShowBankAccounts(!showBankAccounts)}>
                            {showBankAccounts ? <ArrowsReduceDiagonal1Icon /> : <ArrowsExpandDiagonal6Icon />}
                        </Button>
                    </div>
                </div>

                <Button
                    variant='outline'
                    size='small'
                    onClick={() => {
                        setEditingBankAccount(undefined);
                        setShowUpsertForm(true);
                    }}
                    className='mt-2 sm:hidden'
                >
                    {t('create-new-account-label')}
                </Button>
            </div>

            {/* <div>
                Not available in store.
            </div> */}

            {showBankAccounts && <div className='pt-4 flex flex-col gap-4'>
                {bankAccounts.length === 0 && <div className='font-semibold text-danger-500 text-center'>{t('no-bank-accounts')}</div>}

                {bankAccounts.map(account => (
                    <BankAccountItem
                        key={account.id}
                        account={account}
                        onUpdate={account => {
                            setEditingBankAccount(account);
                            setShowUpsertForm(true);
                        }}
                        onDelete={setDeletingAccount}
                    />
                ))}
            </div>}

            <DeleteBankAccountModal
                account={deletingAccount}
                onClose={() => setDeletingAccount(undefined)}
                onDelete={deleteAccount}
            />

            <UpsertBankAccountModal
                bankAccount={editingBankAccount}
                show={showUpsertForm}
                onClose={() => setShowUpsertForm(false)}
                currencies={[]}
            />
        </Card>
    );
}

type BankAccountItemProps = Readonly<{
    account: BankAccountOutput;
    onUpdate: (update: BankAccountOutput) => void;
    onDelete: (account: BankAccountOutput) => void;
}>;

function BankAccountItem({ account, onUpdate, onDelete }: BankAccountItemProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'payments' });

    return (
        <div className='flex justify-between items-center flex-wrap gap-4 p-5 rounded-lg border border-secondary-200'>
            <div className='flex gap-4 items-center'>
                <BankIcon className='h-6 text-secondary-900 shrink-0' />

                <div className='space-y-2'>
                    <div>{account.label}</div>

                    <CurrenciesDisplay currencies={account.currencies} />
                </div>
            </div>

            <div className='flex items-center justify-end'>
                <Button variant='transparent' onClick={() => onUpdate(account)} className='text-primary text-sm'>
                    {t('edit-bank-account-button')}
                </Button>

                <Button variant='transparent' onClick={() => onDelete(account)}>
                    <Trash2Icon className='h-3' />
                </Button>
            </div>
        </div>
    );
}

type CurrenciesDisplayProps = Readonly<{
    currencies: CurrencyId[];
}>;

function CurrenciesDisplay({ currencies }: CurrenciesDisplayProps) {
    return (
        <div className='text-secondary-400'>
            {currencies.map(Currency.label).join(', ')}
        </div>
    );
}

type DeleteBankAccountModalProps = Readonly<{
    account?: BankAccountOutput;
    onClose: () => void;
    onDelete: (deletedLocation: BankAccountOutput) => void;
}>;

function DeleteBankAccountModal({ account, onClose, onDelete }: DeleteBankAccountModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.deleteBankAccountModal' });
    const { addAlert } = useNotifications();

    const deleteBankAccountMutation = trpc.invoicing.deleteBankAccount.useMutation();

    function deleteAccount() {
        if (!account)
            return;

        deleteBankAccountMutation.mutate(account, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                onDelete(account);
            },
        });
    }

    return (
        <Modal.Root
            open={!!account}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='space-y-4'>
                    <Trash2Icon size={32} className='mx-auto text-danger-500 stroke-2' />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='text-center'>
                    {t('text')}
                </div>

                <Modal.Footer className='grid grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={onClose}>
                        {t('cancel-button')}
                    </Button>

                    <SpinnerButton
                        variant='danger'
                        isFetching={deleteBankAccountMutation.isPending}
                        onClick={deleteAccount}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

function PreferencesSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments' });
    const [ showPreferences, setShowPreferences ] = useToggle(false);

    return (
        <Card>
            <div className='flex flex-row items-center justify-between'>
                <div onClick={setShowPreferences.toggle}>
                    <Card.Title className='flex item-center gap-2 cursor-pointer'>
                        <Sliders3Icon className='h-5 text-warning-500' />
                        <span>{t('preferences.title')}</span>
                    </Card.Title>
                </div>

                <Button variant='transparent' onClick={setShowPreferences.toggle}>
                    {!showPreferences && <ArrowsExpandDiagonal6Icon className='h-4' />}
                    {showPreferences && <ArrowsReduceDiagonal1Icon className='h-4' />}
                </Button>
            </div>

            {showPreferences && (
                <PaymentsPreferencesForm />
            )}
        </Card>
    );
}
