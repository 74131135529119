import { useState } from 'react';
import { type BillingPeriod, Paywall, type Plan, CustomerPortal } from '@stigg/react-sdk';
import { SubscriptionCheckoutModal, type SubscriptionPlan } from ':frontend/components/team/subscription';

// const TEAM_OPTION_BUTTON_LINK = 'https://app.lemcal.com/@stepancalta';
const CONTACT_SUPPORT_EMAIL = 'info@flowlance.com';

export function Subscriptions() {
    // const { t } = useTranslation('pages', { keyPrefix: 'subscription' });
    const [ selectedPlan, setSelectedPlan ]  = useState<SubscriptionPlan | undefined>(undefined);

    function onPlanSelect({ plan, selectedBillingPeriod }: {plan: Plan, selectedBillingPeriod: BillingPeriod}) {
        setSelectedPlan({ id: plan.id, billingPeriod: selectedBillingPeriod });
    }

    function onContactSupport() {
        window.location.href = `mailto:${CONTACT_SUPPORT_EMAIL}`;
    }

    return (
        <div className='max-w-[1200px] w-full mx-auto p-4 py-8 md:py-12'>
            <SubscriptionCheckoutModal isOpen={selectedPlan !== undefined} onClose={() => setSelectedPlan(undefined)} plan={selectedPlan}/>

            <CustomerPortal
                productId='product-flowlance'
                onContactSupport={onContactSupport}
                paywallComponent={
                    <Paywall
                        // textOverrides={{
                        //     highlightChip: 'Users\' Favorite',
                        // }}
                        // highlightedPlanId={FIRST_PLAN_ID}
                        onPlanSelected={onPlanSelect}
                        productId='product-flowlance'
                    />
                }
            />
        </div>
    );
}
