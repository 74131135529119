import { forwardRef, useCallback, useRef, useState, type ButtonHTMLAttributes, type ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from './utils';
import { Spinner } from './Spinner';
import { useLayoutEffect } from ':components/hooks';

export const buttonVariants = cva('rounded-full whitespace-nowrap inline-flex items-center justify-center gap-2 select-none disabled:pointer-events-none [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 focus-visible:outline-none', {
    variants: {
        variant: {
            primary: 'text-white bg-primary hover:bg-primary/70 disabled:bg-primary/20',
            secondary: 'text-primary bg-primary-50 hover:bg-primary-100 disabled:bg-primary-50 disabled:opacity-30',
            dark: 'text-white bg-primary-800 hover:bg-primary-800/70 disabled:bg-primary-800/20',
            outline: 'border bg-white hover:border-black disabled:opacity-40',
            ghost: 'text-primary bg-transparent hover:bg-[#f5f5f5] disabled:opacity-40',
            /**
             * This button can be displayed over any kind of background. Use it when there is only a text that acts like a button.
             * It relies on opacity to show its state, so its color can be adjusted as needed. E.g., if on a dark background, it can be set to white.
             */
            transparent: 'bg-transparent hover:opacity-80 active:opacity-60 disabled:opacity-40',
            white: 'border bg-white hover:border-primary-300 disabled:opacity-30',
            danger: 'border border-danger-400 hover:border-danger-600 bg-danger-100 text-danger-800 hover:text-danger-900 disabled:opacity-30', // TODO
            'outline-danger': '', // TODO
        },
        size: {
            exact: 'flex',
            tiny: 'h-7 px-4 text-sm',
            small: 'h-9 px-5 text-base',
            medium: 'h-10 px-5 text-lg',
            large: 'h-11 px-6 text-2lg',
        },
    },
    compoundVariants: [ {
        variant: [ 'outline', 'white', 'danger' ],
        size: 'tiny',
        class: 'px-4b',
    }, {
        variant: [ 'outline', 'white', 'danger' ],
        size: [ 'small', 'medium' ],
        class: 'px-5b',
    }, {
        variant: [ 'outline', 'white', 'danger' ],
        size: 'large',
        class: 'px-6b',
    } ],
    defaultVariants: {
        variant: 'primary',
        size: 'medium',
    },
});

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
    const Component = asChild ? Slot : 'button';

    return (
        <Component
            ref={ref}
            className={cn(buttonVariants({ variant, size, className }))}
            type='button'
            {...props}
        />
    );
});
Button.displayName = 'Button';

type BaseSpinnerButtonProps = Omit<ButtonProps, 'isFetching' | 'fetching' | 'fid' | 'isOverlay'> & {
    /** The icon is like content, but it will be displayed even if the button is fetching. */
    icon?: ReactNode;
};

// Type OR is not ideal here, because we would need to delete the other properties from the rest object.
type SpinnerButtonProps = BaseSpinnerButtonProps & {
    isFetching?: boolean;
    /**
     * If fetching === fid, then the button is fetching.
     * Else if !!fetching, then the button is disabled.
     */
    fetching?: string;
    fid?: string;
    /** If the button is under overlay, it shouldn't be disabled even during fetching. */
    isOverlay?: boolean;
};

export function SpinnerButton(props: BaseSpinnerButtonProps & { isFetching: boolean | undefined }): JSX.Element;

export function SpinnerButton(props: BaseSpinnerButtonProps & { fetching: string | undefined, fid: string, isOverlay?: boolean }): JSX.Element;

/**
 * This component acts like a button that turns into a spinner whenewer isFetching === true.
 * The button is disabled, however its dimensions remain constant.
 */
export function SpinnerButton({ disabled, isFetching, fetching, fid, isOverlay, style, icon, ...rest }: SpinnerButtonProps) {
    const [ measurements, setMeasurements ] = useState<{ width?: number, height?: number }>({});
    const contentRef = useRef<HTMLButtonElement>(null);

    const doMeasurements = useCallback(() => {
        if (!contentRef.current)
            return;

        const newWidth = contentRef.current.getBoundingClientRect().width;
        const newHeight = contentRef.current.getBoundingClientRect().height;

        setMeasurements(({ width, height }) => ({
            width: (!width || newWidth > width) ? newWidth : width,
            height: (!height || newHeight > height) ? newHeight : height,
        }));
    }, []);

    const isFetchingInner = isFetching ?? (fid !== undefined && fetching === fid);
    const isDisabled = !!disabled || isFetchingInner || !(!fetching || isOverlay);

    useLayoutEffect(() => {
        doMeasurements();
        // This should be enought time for all animations to finish.
        const timer = setTimeout(doMeasurements, 500);
        return () => clearTimeout(timer);
    }, [ isFetchingInner, doMeasurements ]);

    return (
        <Button
            {...rest}
            disabled={isDisabled}
            ref={contentRef}
            style={(isFetchingInner ? { ...measurements, ...style } : style)}
        >
            {isFetchingInner ? (
                <Spinner size='sm' />
            ) : (
                rest.children
            )}
            {icon}
        </Button>
    );
}
