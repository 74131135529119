import { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Card } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { NewInvoicingProfileForm } from ':frontend/components/settings/NewInvoicingProfileForm';
import useNotifications from ':frontend/context/NotificationProvider';
import { useMaster } from ':frontend/context/UserProvider';
import { useClients, useToggle } from ':frontend/hooks';
import { type ClientInfoFE } from ':frontend/types/Client';
import { LocaleIcon } from ':frontend/components/common/LocaleToggle';
import type { InvoicingProfileInit, InvoicingProfileOutput } from ':utils/entity/invoicing';
import { trpc } from ':frontend/context/TrpcProvider';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';
import { InvoicingProfileExampleModal } from ':frontend/components/orders/InvoicingProfileExampleModal';
import { FilePlusIcon, ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon, LicenseIcon, Trash2Icon, SquareUserPlusIcon, MediaPlayIcon } from ':components/icons/basic';
import { InvoicingProfileFormPart } from './InvoicingProfileSettings';
import { DeleteProfileModal } from ':frontend/components/settings/DeleteProfileModal';
import { AssignClientModal } from ':frontend/components/settings/AssignClientModal';
import { FIRST_PLAN_ID, StiggFeature } from ':utils/lib/stigg';
import { SubscriptionCheckoutModal } from ':frontend/components/team/subscription';
import { useEntitlement } from ':frontend/lib/stigg';

export function InvoicingProfilesSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profiles' });
    const [ exampleProfile, setExampleProfile ] = useState<InvoicingProfileOutput>();

    return (
        <div className='p-4 py-8 md:py-12 space-y-8'>
            <div className='flex items-center justify-between gap-4'>
                <h1 className='text-2xl font-semibold leading-6'>{t('page-title')}</h1>

                <AddProfileButton className='bg-transparent' />
            </div>

            <InvoicingProfilesTable setExampleProfile={setExampleProfile} />
            <InvoicingProfileExampleModal profile={exampleProfile} onHide={() => setExampleProfile(undefined)} />
        </div>
    );
}

type InvoicingProfilesTableProps = Readonly<{
    setExampleProfile: (profile: InvoicingProfileOutput) => void;
}>;

function InvoicingProfilesTable({ setExampleProfile }: InvoicingProfilesTableProps) {
    const { profiles, setProfiles } = useMaster();
    const { clients } = useClients();

    const setProfile = useCallback((profile: InvoicingProfileOutput) => {
        setProfiles(oldProfiles => {
            const index = oldProfiles.findIndex(p => p.id === profile.id);
            const newProfiles = [ ...oldProfiles ];
            newProfiles[index] = profile;

            return newProfiles;
        });
    }, [ setProfiles ]);

    if (!clients)
        return null;

    return (
        <div className='space-y-4'>
            {profiles.map(profile => (
                <ProfileRow
                    key={profile.id}
                    profile={profile}
                    setProfile={setProfile}
                    allClients={clients}
                    setExampleProfile={setExampleProfile}
                />
            ))}
        </div>
    );
}

type ProfileRowProps = Readonly<{
    profile: InvoicingProfileOutput;
    setProfile: (newProfile: InvoicingProfileOutput) => void;
    allClients: ClientInfoFE[];
    setExampleProfile: (profile: InvoicingProfileOutput) => void;
}>;

function ProfileRow({ profile, setProfile, allClients }: ProfileRowProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profiles' });
    const clients = useMemo(() => allClients?.filter(client => client.invoicingProfileId === profile.id), [ profile, allClients ]);

    const [ showExampleModal, setShowExampleModal ] = useToggle(false);
    const [ showDetails, setShowDetails ] = useState(false);
    const [ showAdvancedSection, setShowAdvancedSection ] = useState(false);

    return (
        <>
            <InvoicingProfileExampleModal profile={showExampleModal ? profile : undefined} onHide={setShowExampleModal.false} />

            <Card>
                <div className='flex flex-row items-center justify-between cursor-pointer' onClick={() => setShowDetails(!showDetails)}>
                    <Card.Title className='flex items-center gap-2 overflow-hidden'>
                        <LocaleIcon locale={profile.locale} size={18} />
                        <span className='truncate'>
                            {profile.title}
                        </span>
                    </Card.Title>

                    <div className='flex items-center gap-4'>
                        <span className='whitespace-nowrap'>{t('number-of-clients', { count: clients.length })}</span>

                        <Button variant='transparent'>
                            {showDetails ? <ArrowsReduceDiagonal1Icon /> : <ArrowsExpandDiagonal6Icon />}
                        </Button>
                    </div>
                </div>

                {showDetails && (
                    <>
                        <Card.Divider />

                        <div className='flex gap-2 flex-nowrap overflow-scroll mb-8 [&::-webkit-scrollbar]:hidden'>
                            <AssignClientButton profile={profile} className='flex-1' />

                            {/* View Clients and View Orders backlogged on Dec 23, 2024 */}

                            {/* <Link to={routesFE.clients.list.path} className='flex-1'>
                                <Button variant='secondary' size='small' className='w-full'>
                                    <UserArrowRightIcon className='h-4' /> {t('view-clients-button')}
                                </Button>
                            </Link> */}

                            {/* <Link to={routesFE.orders.list.path} className='flex-1'>
                                <Button variant='secondary' size='small' className='w-full'>
                                    <FileSendIcon className='h-4' /> {t('view-invoices-button')}
                                </Button>
                            </Link> */}

                            <Button variant='secondary' size='small' onClick={setShowExampleModal.true} className='flex-1'>
                                <MediaPlayIcon className='h-4' /> {t('preview-invoice-button')}
                            </Button>

                            <DeleteButton profile={profile} clients={clients ?? []} />
                        </div>

                        <InvoicingProfileFormPart type='general' profile={profile} setProfile={setProfile} />

                        <div className='flex flex-row items-center justify-between cursor-pointer mt-16' onClick={() => setShowAdvancedSection(!showAdvancedSection)}>
                            <Card.Title className='flex items-center gap-2'>
                                <LicenseIcon className='shrink-0 text-warning-500' />
                                {t('advanced-section')}
                            </Card.Title>

                            <Button variant='transparent'>
                                {showAdvancedSection ? <ArrowsReduceDiagonal1Icon /> : <ArrowsExpandDiagonal6Icon />}
                            </Button>
                        </div>

                        {showAdvancedSection && (
                            <>
                                <Card.Divider />
                                <InvoicingProfileFormPart type='advanced' profile={profile} setProfile={setProfile} />
                            </>
                        )}
                    </>
                )}
            </Card></>
    );
}

type AddProfileButtonProps = Readonly<{
    className?: string;
}>;

export function AddProfileButton({ className }: AddProfileButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profiles' });
    const { setProfiles } = useMaster();
    const [ showProfileModal, setShowProfileModal ] = useToggle(false);
    const [ showUpsell, setShowUpsell ] = useToggle(false);
    const isEnabled = useEntitlement(StiggFeature.Invoicing);

    const createProfile = useCallback((newProfile: InvoicingProfileOutput) => {
        setProfiles(oldProfiles => [ ...oldProfiles, newProfile ]);
    }, [ setProfiles ]);

    return (<>
        <Button
            variant='outline'
            size='small'
            className={className}
            onClick={isEnabled ? setShowProfileModal.true : setShowUpsell.true}
        >
            <FilePlusIcon size={22} className='mr-2' />{t('new-profile-button')}
        </Button>

        <NewProfileModal
            show={showProfileModal}
            onProfileCreated={createProfile}
            onClose={setShowProfileModal.false}
        />

        <SubscriptionCheckoutModal isOpen={showUpsell} onClose={setShowUpsell.false} plan={{ id: FIRST_PLAN_ID }}/>
    </>);
}

type NewProfileModalProps = Readonly<{
    show: boolean;
    onClose: () => void;
    onProfileCreated: (newProfile: InvoicingProfileOutput) => void;
}>;

function NewProfileModal({ show, onClose, onProfileCreated }: NewProfileModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'newProfileModal' });
    const createInvoicingProfileMutation = trpc.invoicing.createInvoicingProfile.useMutation();
    const { addAlert } = useNotifications();

    function onSubmit(init: InvoicingProfileInit) {
        createInvoicingProfileMutation.mutate(init, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: response => {
                onProfileCreated(response);
                onClose();
            },
        });
    }

    return (
        <Modal.Root
            open={show}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='gap-6 max-w-lg' closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title className='flex items-center gap-2'>
                        <FilePlusIcon className='h-5 text-danger-600' />{t('title')}
                    </Modal.Title>
                </Modal.Header>

                <Card.Divider className='my-0' />

                <NewInvoicingProfileForm
                    onSubmit={onSubmit}
                    isFetching={createInvoicingProfileMutation.isPending}
                />
            </Modal.Content>
        </Modal.Root>
    );
}


type DeleteButtonProps = Readonly<{
    profile: InvoicingProfileOutput;
    clients: ClientInfoFE[];
    className?: string;
}>;

function DeleteButton({ profile, clients, className }: DeleteButtonProps) {
    const [ showModal, setShowModal ] = useToggle(false);
    const { setProfiles } = useMaster();

    const profileDeleted = useCallback(() => {
        setProfiles(oldProfiles => oldProfiles.filter(p => p.id !== profile.id));
    }, [ profile.id, setProfiles ]);

    return (<>
        <DeleteProfileModal
            profile={showModal ? profile : undefined}
            allClients={clients}
            onClose={setShowModal.false}
            onDelete={profileDeleted}
        />

        <Button variant='secondary' size='small' onClick={setShowModal.true} className={className}>
            <Trash2Icon className='h-4' />
        </Button>
    </>);
}

type AssignClientButtonProps = Readonly<{
    profile: InvoicingProfileOutput;
    className?: string;
}>;

function AssignClientButton({ profile, className }: AssignClientButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profiles' });
    const [ showModal, setShowModal ] = useToggle(false);

    return (<>
        {profile && showModal && <AssignClientModal
            profile={profile}
            onClose={setShowModal.false}
        />}

        <Button variant='secondary' size='small' onClick={setShowModal.true} className={className}>
            <SquareUserPlusIcon className='h-4' /> {t('add-client-button')}
        </Button>
    </>);
}
