import { useTranslation } from 'react-i18next';
import { OrdersTable } from ':frontend/components/orders/OrdersTable';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { Card } from ':components/shadcn';
import { ExportOrdersModal } from ':frontend/components/orders/ExportOrdersModal';
import { OrderStateFilter } from ':frontend/components/common/filters/OrderStateFilter';
import { CreatedAtStartFilter, CreatedAtEndFilter } from ':frontend/components/common/filters/CreatedAtFilter';
import { OrderTypeFilter } from ':frontend/components/common/filters/OrderTypeFilter';
import { createOrderClientFilter } from ':frontend/components/common/filters/OrderClientFilter';
import { useFilters, type UseFiltersControl } from ':frontend/components/common/filters/FilterRow';
import { useClients } from ':frontend/hooks';
import { useMemo } from 'react';
import type { OrderState } from ':utils/entity/invoicing';
import type { Id } from ':utils/id';
import type { DateTime } from 'luxon';
import type { OrderType } from ':utils/entity/order';
import { TrialUpsellBanner } from ':frontend/components/team/subscription';

export default function Orders() {
    const { t } = useTranslation('pages', { keyPrefix: 'orders' });

    const { clients } = useClients();

    const filters = useMemo(() => [
        OrderTypeFilter,
        OrderStateFilter,
        CreatedAtStartFilter,
        CreatedAtEndFilter,
        createOrderClientFilter(clients ?? []),
    ], [ clients ]);

    const filtersControl = useFilters(filters);

    return (
        <div className='px-4'>
            <TrialUpsellBanner />

            <div className='max-w-[1200px] mx-auto py-8 space-y-8'>
                <div className='flex justify-between items-center'>
                    <h1 className='leading-9 text-2xl text-secondary-700 font-semibold'>{t('page-title')}</h1>

                    <ExportOrdersModal inputFilters={filtersControl} />
                </div>

                <InfoCard infoKey='orders' />

                <Card className='max-lg:p-0 max-lg:bg-transparent max-lg:shadow-none max-lg:border-none'>
                    <OrdersTable filtersControl={filtersControl} filtersToServer={filtersToServer} showClients />
                </Card>
            </div>
        </div>
    );
}

function filtersToServer(control: UseFiltersControl) {
    return {
        type: control.toServer(OrderTypeFilter.name) as OrderType[] | undefined,
        state: control.toServer(OrderStateFilter.name) as OrderState[] | undefined,
        client: control.toServer(createOrderClientFilter.filterName) as Id[] | undefined,
        createdAtStart: control.toServer(CreatedAtStartFilter.name) as DateTime | undefined,
        createdAtEnd: control.toServer(CreatedAtEndFilter.name) as DateTime | undefined,
    };
}
