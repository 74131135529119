import { type default as React, type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react';
import { createExampleOrderStats } from ':frontend/types/orders/exampleOrderStats';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import { Chart as ChartJS, LineElement, PointElement, TimeScale, LinearScale, Filler, Tooltip as ChartJSTooltip, type ChartData, type ChartOptions } from 'chart.js';
import { MoneyDisplay } from ':components/custom';
import { BoxHeartIcon, CoinsIcon, TrophyIcon, BoxMinusIcon, ChartComboIcon, ThumbsUpIconScaling } from ':components/icons/basic';
import { amountToDecimal, toMoney } from ':utils/money';
import { isOrderLead, type OrderStatsOutput } from ':utils/entity/order';
import { trpc } from ':frontend/context/TrpcProvider';
import { getEnumValues } from ':utils/common';
import { Spinner, StringSelect, Card } from ':components/shadcn';
import { cn } from ':components/shadcn/utils';
import { productStyles } from ':components/store/product/ProductCard';
import tailwindConfig from ':frontend/../tailwind.config';
import { roundToFixedDecimal } from ':utils/math';
import { InfoTooltip } from '../common/InfoTooltip';
import clsx from 'clsx';
import { useTailwindMediaQuery } from ':frontend/hooks';
import { freeTierPredefinedRanges, PredefinedRangeType, type PredefinedRange } from ':utils/dateTime';
import { productsWithoutCheckout } from ':utils/entity/product';
import { StiggFeature } from ':utils/lib/stigg';
import { UpsellButton } from ':frontend/components/team/subscription';
import { useEntitlement } from ':frontend/lib/stigg';

ChartJS.register(LineElement, PointElement, TimeScale, LinearScale, Filler, ChartJSTooltip);

export const PERIODS_COUNT = 4;

export function OrderStatsDisplay() {
    const [ range, setRange ] = useState<PredefinedRange>({ type: PredefinedRangeType.last7Days });

    const statsHistoryEnabled = useEntitlement(StiggFeature.StatsHistory);
    const isRangeAvailable = statsHistoryEnabled === undefined || freeTierPredefinedRanges.includes(range.type);

    const orderStatsQuery = trpc.order.getOrderStats.useQuery(range, { enabled: isRangeAvailable });
    const trpcUtils = trpc.useUtils();

    const stats = useMemo(() => {
        return isRangeAvailable
            ? orderStatsQuery.data
            : createExampleOrderStats(range.type);
    }, [ orderStatsQuery.data, isRangeAvailable, range.type ]);

    useEffect(() => {
        // TODO this shouldn't be in a useEffect
        // move to event handler or configure the useQuery to invalidate automatically
        // is it even needed?
        trpcUtils.order.getOrderStats.invalidate();
    }, [ range ]);

    const isDesktop = useTailwindMediaQuery({ minWidth: 'lg' });
    const showUpsell = !isRangeAvailable
        ? (isDesktop ? 'desktop' : 'phone')
        : undefined;

    return (
        <Card className='max-lg:p-0 max-lg:max-w-screen-md max-lg:bg-transparent max-lg:shadow-none max-lg:border-none'>
            <StatsHeader range={range} setRange={setRange} />

            {stats ? (
                <div className='relative space-y-8'>
                    {showUpsell === 'phone' && <StatsUpsell />}

                    <StatsCharts stats={stats} showUpsell={showUpsell} />

                    <div className='max-lg:hidden w-full border-b' />

                    <StatsProducts stats={stats} showUpsell={showUpsell} />
                </div>
            ) : (
                <div className='flex w-full h-full justify-center items-center'>
                    <Spinner />
                </div>
            )}
        </Card>
    );
}

type StatsInnerProps = Readonly<{
    stats: OrderStatsOutput;
    showUpsell: 'phone' | 'desktop' | undefined;
}>;

enum OrderStatsMetric {
    visits = 'visits',
    clicks = 'clicks',
    /** Leads are orders with price equal to 0. See {@link isOrderLead}. */
    leadsCount = 'leadsCount',
    /** Sales are orders with price not equal to 0. See {@link isOrderLead}. */
    salesCount = 'salesCount',
    ordersValue = 'ordersValue',
}

function StatsCharts({ stats, showUpsell }: StatsInnerProps) {
    const [ metric, setMetric ] = useState<OrderStatsMetric>(OrderStatsMetric.visits);

    const {
        leadsCount,
        salesCount,
        ordersValue,
    } = useMemo(() => {
        const leadsCount = stats.orders.filter(isOrderLead).length;
        const totalAmount = stats.orders.reduce((total, order) => total + order.total, 0);
        return {
            leadsCount,
            salesCount: stats.orders.length - leadsCount,
            ordersValue: toMoney(totalAmount, stats.currency),
        };
    }, [ stats ]);

    return (
        <div className='relative flex flex-col gap-6'>
            {showUpsell === 'desktop' && <StatsUpsell />}

            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 w-full'>
                <ChartSwitch number={stats.visits.length} type={OrderStatsMetric.visits} metric={metric} setMetric={setMetric} />
                <ChartSwitch number={stats.clicks.length} type={OrderStatsMetric.clicks} metric={metric} setMetric={setMetric} />
                <ChartSwitch number={leadsCount} type={OrderStatsMetric.leadsCount} metric={metric} setMetric={setMetric} />
                <ChartSwitch number={salesCount} type={OrderStatsMetric.salesCount} metric={metric} setMetric={setMetric} />
                <ChartSwitch number={<MoneyDisplay money={ordersValue} noColor />} type={OrderStatsMetric.ordersValue} metric={metric} setMetric={setMetric} />
            </div>

            <ChartDisplay stats={stats} type={metric} showUpsell={showUpsell} />
        </div>
    );
}

type ChartSwitchProps = Readonly<{
    number: React.ReactNode;
    type: OrderStatsMetric;
    metric: OrderStatsMetric;
    setMetric: (metric: OrderStatsMetric) => void;
}>;

function ChartSwitch({ number, type, metric, setMetric }: ChartSwitchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderStatsDisplay.metrics' });
    const { t: tt } = useTranslation('components', { keyPrefix: 'orderStatsDisplay.metricsTooltips' });

    const isActive = metric === type;
    const className = cn(
        'p-8 flex flex-col gap-2 items-start max-lg:items-center rounded-xl border max-lg:bg-white max-lg:px-0 max-lg:',
        isActive ? 'group active bg-primary-50 border-primary-100 max-lg:border-primary' : 'border-secondary-100 bg-[#fbfafd] max-lg:border-white',
    );

    return (
        <button className={className} onClick={() => setMetric(type)} disabled={isActive}>
            <span className='group-[.active]:text-primary text-2lg font-semibold max-lg:text-secondary-700'>{number}</span>
            <span className='group-[.active]:text-primary group-[.active]:opacity-100 text-secondary/75 flex gap-1 items-center'>
                {t(type)}
                <InfoTooltip text={tt(type)} side='bottom' />
            </span>
        </button>
    );
}

function ChartDisplay({ stats, type }: StatsInnerProps & { type: OrderStatsMetric }) {
    const data = useMemo(() => {
        const { data } = getChartData(stats, type);

        return {
            // labels,
            datasets: [ {
                data,
                borderColor: tailwindConfig.theme.colors.primary.DEFAULT,
                fill: 'start',
                backgroundColor: context => {
                    const { ctx, chartArea } = context.chart;
                    if (!chartArea)
                        return;  // this happens on initial load

                    const height = chartArea.bottom - chartArea.top;
                    // TODO don't create the gradient object every time?
                    // see https://www.chartjs.org/docs/latest/samples/advanced/linear-gradient.html
                    const gradient = ctx.createLinearGradient(0, 0, 0, height);
                    gradient.addColorStop(0, 'rgba(128, 73, 236, 0.2)');
                    gradient.addColorStop(1, 'rgba(128, 73, 236, 0.1)');
                    return gradient;
                },
                pointStyle: false,
            } ],
        } as ChartData<'line', { x: string, y: number }[]>;
    }, [ stats, type ]);

    const options: ChartOptions<'line'> = {
        responsive: true,  // probably not needed for production
        scales: {
            x: {
                // or 'timeseries'?
                type: 'time',
                time: {
                    unit: stats.rangeType === PredefinedRangeType.last12Months
                        ? 'month'
                        : stats.rangeType === PredefinedRangeType.last7Days || stats.rangeType === PredefinedRangeType.last30Days
                            ? 'day'
                            : 'hour',
                },
            },
            y: {
                min: 0,
                ticks: {
                    stepSize: 1,
                    maxTicksLimit: 6,
                },
            },
        },
    };

    return (
        <div className='max-lg:p-4 max-lg:rounded-lg bg-white'>
            <Line data={data} options={options} />
        </div>
    );
}

function getChartData(stats: OrderStatsOutput, type: OrderStatsMetric) {
    if (stats.rangeType === PredefinedRangeType.custom)
        throw new Error('Custom range not implemented');

    const groupedMetrics = new Map<string, number>();
    let dateFormat: string;

    // date formats compatible with Luxon + Chart.js:
    // https://github.com/chartjs/chartjs-adapter-luxon/blob/master/src/index.js#L4
    if (stats.rangeType === PredefinedRangeType.last12Months) {
        dateFormat = 'yyyy-MM';  // Group by month
        let currentDate = stats.range.start;
        while (currentDate <= stats.range.end) {
            const key = currentDate.toFormat(dateFormat);
            groupedMetrics.set(key, 0);
            currentDate = currentDate.plus({ months: 1 });
        }
    }
    else if (stats.rangeType === PredefinedRangeType.today) {
        dateFormat = 'HH'; // Group by hour
        let currentDate = stats.range.start;
        while (currentDate <= stats.range.end) {
            const key = currentDate.toFormat(dateFormat);
            groupedMetrics.set(key, 0);
            currentDate = currentDate.plus({ hours: 1 });
        }
    }
    else {
        dateFormat = 'yyyy-MM-dd';  // Group by day
        let currentDate = stats.range.start;
        while (currentDate <= stats.range.end) {
            const key = currentDate.toFormat(dateFormat);

            groupedMetrics.set(key, 0);
            currentDate = currentDate.plus({ days: 1 });
        }
    }

    switch (type) {
    case OrderStatsMetric.visits:
        for (const visit of stats.visits) {
            const key = visit.createdAt.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + 1);
        }
        break;
    case OrderStatsMetric.clicks:
        for (const click of stats.clicks) {
            const key = click.createdAt.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + 1);
        }
        break;
    case OrderStatsMetric.leadsCount:
        for (const lead of stats.orders.filter(isOrderLead)) {
            const key = lead.createdAt.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + 1);
        }
        break;
    case OrderStatsMetric.salesCount:
        for (const sale of stats.orders.filter(order => !isOrderLead(order))) {
            const key = sale.createdAt.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + 1);
        }
        break;
    case OrderStatsMetric.ordersValue:
        for (const order of stats.orders) {
            const key = order.createdAt.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + amountToDecimal(order.total));
        }
        break;
    }

    // Safari doesn't support Iterator.map() yet, the .map() must be on the array, not on the entries()
    const datapoints = [ ...groupedMetrics.entries() ].map(([ key, value ]) => ({ x: key, y: value }));
    return {
        data: datapoints,
        labels: datapoints.map(point => point.x),
    };
}

function StatsProducts({ stats, showUpsell }: StatsInnerProps) {
    const [ sortType, setSortType ] = useState(ProductSortType.mostProfitable);

    return (
        <div className='space-y-6'>
            <div className={clsx('-mx-4 lg:-mx-6', showUpsell === 'phone' ? 'overflow-hidden max-w-full' : 'fl-hide-scrollbar overflow-x-auto')}>
                <div className='flex flex-row mx-auto w-max gap-2.5 px-6'>
                    {getEnumValues(ProductSortType).map(switchType => (
                        <ProductSortButton key={switchType} type={switchType} isActive={sortType === switchType} onClick={() => setSortType(switchType)} />
                    ))}
                </div>
            </div>

            <div className={clsx('-mx-4 lg:-mx-6', showUpsell === 'phone' ? 'overflow-hidden max-w-full' : 'fl-hide-scrollbar overflow-x-auto')}>
                <div className='relative min-w-[600px] px-6'>
                    {showUpsell === 'desktop' && <StatsUpsell className='inset-x-6' />}

                    <ProductsTable stats={stats} sortType={sortType} />

                    <div className='h-6' />

                    <LinksTable stats={stats} />
                </div>
            </div>
        </div>
    );
}

function ProductsTable({ stats, sortType }: { stats: OrderStatsOutput, sortType: ProductSortType }) {
    const { t } = useTranslation('components', { keyPrefix: 'orderStatsDisplay.productsTable' });
    const sortedProducts = useMemo(() => {
        const checkoutProducts = stats.products.filter(product => !productsWithoutCheckout.includes(product.type));
        return sortProductsBySwitchType(checkoutProducts, sortType);
    }, [ stats, sortType ]);

    return (<>
        <div className='pb-4 flex flex-row w-full px-6 opacity-70'>
            <div className='w-2/6'>{t('product')}</div>
            <div className='w-1/6 flex gap-1 items-center justify-end'>
                {t('clicks')}
                <InfoTooltip text={t('clicks-tooltip')} side='bottom' />
            </div>
            <div className='w-1/6 flex gap-1 items-center justify-end'>
                {t('ordersCount')}
                <InfoTooltip text={t('ordersCount-tooltip')} side='bottom' />
            </div>
            <div className='w-1/6 flex gap-1 items-center justify-end'>
                {t('conversion')}
                <InfoTooltip text={t('conversion-tooltip')} side='bottom' />
            </div>
            <div className='w-1/6 flex gap-1 items-center justify-end'>
                {t('ordersValue')}
                <InfoTooltip text={t('ordersValue-tooltip')} side='bottom' />
            </div>
        </div>

        <div className='flex flex-col w-full rounded-2xl border bg-white'>
            {sortedProducts.map(product => (
                <div className='flex flex-row items-center w-full border-b py-5 px-6 last:border-b-0' key={product.id}>
                    <div className='w-2/6 flex gap-3 items-center'>
                        {productStyles[product.type].icon({ size: 'md', className: 'shrink-0' })}
                        <span className={clsx(productStyles[product.type].color, 'text-lg/5 truncate')}>
                            {product.title}
                        </span>
                    </div>
                    <div className='w-1/6 text-right'>{product.clicks}</div>
                    <div className='w-1/6 text-right'>{product.ordersCount}</div>
                    <div className='w-1/6 text-right'>{computeConversion(product.ordersCount, product.clicks)}</div>
                    <div className='w-1/6 text-right'><MoneyDisplay amount={product.ordersValue} currency={stats.currency} /></div>
                </div>
            ))}

            {!sortedProducts.length && (
                <div className='text-center py-4 text-lg/6 text-secondary-400'>{t('no-products-text')}</div>
            )}
        </div>
    </>);
}

function computeConversion(ordersCount: number, clicks: number) {
    if (clicks === 0 && ordersCount === 0)
        return '\u2014';  // m-dash
    if (clicks === 0 && ordersCount !== 0)
        return '\u221e';  // infinity

    const rate = roundToFixedDecimal((ordersCount / clicks) * 100, 2);

    return `${rate} %`;
}

function LinksTable({ stats }: { stats: OrderStatsOutput }) {
    const { t } = useTranslation('components', { keyPrefix: 'orderStatsDisplay.linksTable' });
    const links = useMemo(() => stats.products.filter(product => productsWithoutCheckout.includes(product.type)), [ stats ]);

    return (<>
        <div className='pb-4 flex flex-row w-full px-6 opacity-70'>
            <div className='w-2/6'>{t('product')}</div>
            <div className='w-1/6 flex gap-1 items-center justify-end'>
                {t('clicks')}
                <InfoTooltip text={t('clicks-tooltip')} side='bottom' />
            </div>
        </div>

        <div className='flex flex-col w-full rounded-2xl border bg-white'>
            {links.map(product => (
                <div className='flex flex-row items-center w-full border-b py-5 px-6 last:border-b-0' key={product.id}>
                    <div className='w-2/6 flex gap-3 items-center'>
                        {productStyles[product.type].icon({ size: 'md', className: 'shrink-0' })}
                        <span className={clsx(productStyles[product.type].color, 'text-lg/5 truncate')}>
                            {product.title}
                        </span>
                    </div>
                    <div className='w-1/6 text-right'>{product.clicks}</div>
                </div>
            ))}

            {!links.length && (
                <div className='text-center py-4 text-lg/6 text-secondary-400'>{t('no-products-text')}</div>
            )}
        </div>
    </>);
}

export function sortProductsBySwitchType<TProduct extends { ordersCount: number, ordersValue: number }>(products: TProduct[], type: ProductSortType): TProduct[] {
    switch (type) {
    case ProductSortType.mostProfitable:
        return products.sort((a, b) => b.ordersValue - a.ordersValue);
    case ProductSortType.mostSelling:
        return products.sort((a, b) => b.ordersCount - a.ordersCount);
    case ProductSortType.leastProfitable:
        return products.sort((a, b) => a.ordersValue - b.ordersValue);
    case ProductSortType.leastSelling:
        return products.sort((a, b) => a.ordersCount - b.ordersCount);
    }
}

export enum ProductSortType {
    mostProfitable = 'mostProfitable',
    mostSelling = 'mostSelling',
    leastProfitable = 'leastProfitable',
    leastSelling = 'leastSelling',
}

const productSortIcons = {
    [ProductSortType.mostProfitable]: <TrophyIcon size={12} />,
    [ProductSortType.mostSelling]: <BoxHeartIcon size={12} />,
    [ProductSortType.leastProfitable]: <CoinsIcon size={12} />,
    [ProductSortType.leastSelling]: <BoxMinusIcon size={12} />,
} as const;

type ProductSortButtonProps = {
    type: ProductSortType;
    isActive: boolean;
    onClick: () => void;
};

export function ProductSortButton({ type, isActive, onClick }: ProductSortButtonProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderStatsDisplay.productSorts' });

    const className = cn(
        'shrink-0 h-10 px-4 flex flex-row items-center gap-1 rounded-full max-lg:px-4b border max-lg:bg-white',
        isActive && 'bg-primary-50 border-primary-100 text-primary max-lg:border-primary',
        !isActive && 'border-secondary-100 bg-[#fbfafd] max-lg:border-white',
    );

    // TODO traingle at the bottom

    return (
        <button className={className} onClick={onClick} disabled={isActive}>
            <span className='flex items-center gap-1'>{productSortIcons[type]} {t(type)}</span>
        </button>
    );
}

type StatsHeaderProps = Readonly<{
    range: PredefinedRange;
    setRange: Dispatch<SetStateAction<PredefinedRange>>;
}>;

function StatsHeader({ range, setRange }: StatsHeaderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderStatsDisplay' });
    const { t: tr } = useTranslation('components', { keyPrefix: 'orderStatsDisplay.range' });

    return (
        <div className='flex max-md:flex-col max-md:items-start items-center justify-between gap-4 mb-6 max-lg:mb-4'>
            <h1 className='flex gap-2 items-center font-semibold text-2xl text-secondary-700'>
                <ThumbsUpIconScaling size={22} />
                {t('title')}
            </h1>

            <div className='w-full max-w-36'>
                <StringSelect
                    value={range.type}
                    onChange={value => value && value in PredefinedRangeType && value !== PredefinedRangeType.custom && setRange({ type: value } as PredefinedRange)}
                    options={getEnumValues(PredefinedRangeType).filter(value => value !== PredefinedRangeType.custom)}
                    t={tr}
                    immutableProps={{
                        size: 'compact',
                        variant: 'outline-transparent',
                    }}
                    styles={{
                        menu(base) {
                            return {
                                ...base,
                                zIndex: 100,
                            };
                        },
                    }}
                />
            </div>
        </div>
    );
}

export function StatsUpsell({ className }: { className?: string }) {
    const { t } = useTranslation('components', { keyPrefix: 'orderStatsDisplay' });

    return (
        <div className={cn('absolute inset-0 flex items-center justify-center bg-primary-200/10 backdrop-blur-md rounded-2xl z-10', className)}>
            <Card className='flex flex-col items-center p-4 border-black/20 rounded-2xl w-[265px]'>
                <ChartComboIcon size={24} className='text-primary mb-3' />

                <span className='leading-[18px] text-center mb-4'>{t('upgrade-text')}</span>

                <UpsellButton text={t('upgrade-button')} className='w-full' feature={StiggFeature.StatsHistory} />
            </Card>
        </div>
    );
}
