import useNotifications, { type AddAlertFunction } from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { useMaster, type MasterContext } from ':frontend/context/UserProvider';
import { type CurrencyId, type TaxRate } from ':utils/money';
import { toNumber, transformToPositiveIntegerOrEmpty, transformToPrice } from ':utils/math';
import { Updator, Validator, zodRule, type FormErrors, type FormPath, type RulesDefinition } from ':frontend/utils/updator';
import { type ProductUpsert, type BaseProductUpsert, ProductType, type ProductPricing, type BundleProductUpsert, type SessionProductUpsert, type DigitalProductUpsert, type CustomProductUpsert, zBaseProductUpsert, type ProductOutput, type LeadProductUpsert, ProductDisplayType, type LinkProductUpsert, type ReferralProductUpsert, ProductLayout, productsWithPlaceholderThumbnail, type DigitalPayloadUpsert, type DigitalPayload, type FullProductUpsert } from ':utils/entity/product';
import { useEffect, useMemo, useReducer, type Dispatch } from 'react';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { routesFE } from ':utils/routes';
import { createSlug } from ':utils/common';
import type { Id } from ':utils/id';
import type { NavigateFunction } from 'react-router-dom';
import { optionalStringToPut } from ':frontend/utils/common';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { createTFunctionWithVariant, type TimezoneCode } from ':utils/i18n';
import type { ProductPreview } from ':components/store/product/ProductCard';
import type { LocationOutput } from ':utils/entity/location';
import { useBlockerModal } from ':frontend/hooks';
import { DateTime } from 'luxon';
import { dateRangesToWeekdays, minutesToSeconds, secondsToMinutes, Weekday, weekdaysToDateRanges, type DateRange } from ':utils/dateTime';
import { fileInputValueToServer, type FileInputValue } from ':components/custom';
import { amountToDecimal, amountFromDecimal } from ':utils/money';
import { MAX_FILE_SIZE_MB } from ':backend/utils/common';
import { zPageUpsert, type PageOutput, type PageUpsert } from ':utils/entity/page';
import { MarkdownInputControl } from '../common/MarkdownInput';

/** Use ProductType if creating a new product. Otherwise provide product (and landing page). */
export type ProductFormsInput = {
    type: ProductType;
    usecase: NewProductUsecase;
} | {
    product: ProductOutput;
    landing?: PageOutput;
};

/** There are different paths the user might take when creating a new product. */
export enum NewProductUsecase {
    Product = 'product',
    Landing = 'landing',
}

export function useProductForms(input: ProductFormsInput, blockAlways: boolean) {
    const context = useMaster();
    const { t } = useTranslation();
    const [ state, dispatch ] = useReducer(productFormsReducer, { input, context, t }, createInitialState);

    const { navigateUnblocked, control } = useBlockerModal(blockAlways || state.isDirty);
    const { addAlert } = useNotifications();

    const createMutation = trpc.product.createProduct.useMutation();
    const updateMutation = trpc.product.updateProduct.useMutation();
    const trpcUtils = trpc.useUtils();

    const syncFunction = useMemo(() =>
        createProductSync(createMutation, updateMutation, trpcUtils, addAlert, navigateUnblocked, dispatch),
    [ createMutation, updateMutation, trpcUtils, addAlert, navigateUnblocked ]);

    useEffect(() => {
        if (!state.sync)
            return;

        syncFunction(state);
    }, [ !!state.sync ]);

    return [
        state,
        dispatch,
        createMutation.isPending || updateMutation.isPending,
        control,
        navigateUnblocked,
    ] as const;
}

function createProductSync(
    createMutation: ReturnType<typeof trpc.product.createProduct.useMutation>,
    updateMutation: ReturnType<typeof trpc.product.updateProduct.useMutation>,
    trpcUtils: ReturnType<typeof trpc.useUtils>,
    addAlert: AddAlertFunction,
    navigateUnblocked: NavigateFunction,
    dispatch: ProductFormsDispatch,
) {
    function create(upsert: FullProductUpsert, usecase: NewProductUsecase) {
        createMutation.mutate(upsert, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
                dispatch({ type: 'error' });
            },
            onSuccess: data => {
                addAlert(createTranslatedSuccessAlert('pages:productDetail:create-success'));
                trpcUtils.product.getFullProduct.setData({ id: data.product.id }, data);
                const route = usecase === NewProductUsecase.Landing ? routesFE.store.resolve({ key: 'overview' }) : routesFE.products.list;
                navigateUnblocked(route);
            },
        });
    }

    function update(upsert: FullProductUpsert, id: Id) {
        updateMutation.mutate({ ...upsert, id }, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
                dispatch({ type: 'error' });
            },
            onSuccess: data => {
                addAlert(createTranslatedSuccessAlert('pages:productDetail:update-success'));
                trpcUtils.product.getFullProduct.setData({ id: data.product.id }, data);
                navigateUnblocked(routesFE.products.list);
            },
        });
    }

    return (state: ProductFormsState) => {
        if (!state.sync)
            return;

        const sync = state.sync;

        if (state.original)
            update(sync.upsert, state.original.product.id);
        else
            create(sync.upsert, state.usecase!);
    };
}

/**
 * A phase is not the same thing as a form. Phase might consist of multiple forms.
 */
export enum ProductFormsPhase {
    ThumbnailDetails = 'thumbnail-details',
    Landing = 'landing',
    Checkout = 'checkout',
    /** Used when creating a new product via the landing page. */
    LandingDetails = 'landing-details',
}

export type ProductFormsState = {
    /** The original product (and landing page). If it's defined, we are editing it. Otherwise, a new product is going to be created. */
    original: {
        product: ProductOutput;
        landing: PageOutput | undefined;
    } | undefined;
    /** Defined only for new products. */
    usecase: NewProductUsecase | undefined;
    type: ProductType;
    /** If at least one form dispatch was called. */
    isDirty: boolean;
    phase: ProductFormsPhase;
    thumbnail: {
        form: ThumbnailFormState;
        formErrors?: FormErrors;
        wasSubmitted?: boolean;
    };
    landing: {
        form: LandingFormState;
        formErrors?: FormErrors;
        wasSubmitted?: boolean;
    };
    details: {
        form: DetailsFormState;
        formErrors?: FormErrors;
        wasSubmitted?: boolean;
    };
    checkout: {
        form: CheckoutFormState;
        formErrors?: FormErrors;
        wasSubmitted?: boolean;
        isSlugTouched?: boolean;
    };
    sync?: SyncState;
};

function createInitialState({ input, context, t }: { input: ProductFormsInput, context: MasterContext, t: TFunction }): ProductFormsState {
    const common = {
        isDirty: false,
        thumbnail: { form: createInitialThumbnailFormState(input, t) },
        landing: { form: createInitialLandingFormState(input) },
        details: { form: createInitialDetailsFormState(input, context) },
        checkout: { form: createInitialCheckoutFormState(input, context, t) },
    };

    if ('product' in input) {
        // We are editing an existing product.
        const { product, landing } = input;
        return {
            ...common,
            original: { product, landing },
            type: product.type,
            phase: ProductFormsPhase.ThumbnailDetails,
            usecase: undefined,
        };
    }

    // We are creating a new product.
    const { type, usecase } = input;

    const output = {
        ...common,
        original: undefined,
        type,
        phase: usecase === NewProductUsecase.Landing ? ProductFormsPhase.LandingDetails : ProductFormsPhase.ThumbnailDetails,
        usecase,
    };

    return output;
}

type ProductFormsAction = ErrorAction | SyncAction | PhaseAction | ThumbnailAction | LandingAction | DetailsAction | CheckoutAction;

export type ProductFormsDispatch = Dispatch<ProductFormsAction>;

function productFormsReducer(state: ProductFormsState, action: ProductFormsAction): ProductFormsState {
    console.log('Reduce:', state, action);

    // TODO This doesn't include editing the landing page content as that's handled outside of the reducer actions.
    if ([ 'thumbnail', 'landing', 'details', 'checkout' ].includes(action.type))
        state.isDirty = true;

    switch (action.type) {
    case 'sync': return sync(state);
    case 'phase': return phase(state, action);
    case 'thumbnail': return thumbnail(state, action);
    case 'landing': return landing(state, action);
    case 'details': return details(state, action);
    case 'checkout': return checkout(state, action);
    case 'error': return { ...state, sync: undefined };
    }
}

type ErrorAction = {
    type: 'error';
}

/** Save the product (create or update). The current form is validated first. */
type SyncAction = {
    type: 'sync';
};

function sync(state: ProductFormsState): ProductFormsState {
    if (state.sync)
        return state;

    const { newState, isValid } = validateCurrentForms(state);

    if (isValid) {
        newState.sync = {
            upsert: {
                product: createProductUpsert(newState),
                landing: createLandingUpsert(newState),
            },
        };
    }

    return newState;
}

function validateCurrentForms(state: ProductFormsState): { newState: ProductFormsState, isValid: boolean } {
    switch (state.phase) {
    case ProductFormsPhase.ThumbnailDetails: {
        const thumbnailErrors = Validator.validate(state.thumbnail.form, thumbnailRules);
        const detailsErrors = Validator.validate(state.details.form, detailsRules);

        return {
            newState: {
                ...state,
                thumbnail: { ...state.thumbnail, formErrors: thumbnailErrors, wasSubmitted: true },
                details: { ...state.details, formErrors: detailsErrors, wasSubmitted: true } },
            isValid: !thumbnailErrors && !detailsErrors,
        };
    }
    case ProductFormsPhase.Landing: {
        const landingErrors = Validator.validate(state.landing.form, landingRules);
        return {
            newState: { ...state, landing: { ...state.landing, formErrors: landingErrors, wasSubmitted: true } },
            isValid: !landingErrors,
        };
    }
    case ProductFormsPhase.Checkout: {
        const checkoutErrors = Validator.validate(state.checkout.form, checkoutRules);
        return {
            newState: { ...state, checkout: { ...state.checkout, formErrors: checkoutErrors, wasSubmitted: true } },
            isValid: !checkoutErrors,
        };
    }
    case ProductFormsPhase.LandingDetails: {
        // No need to validate thumbnail form here. Its values will be filled from the landing form later.
        const detailsErrors = Validator.validate(state.details.form, detailsRules);
        const landingErrors = Validator.validate(state.landing.form, landingRules);

        return {
            newState: {
                ...state,
                details: { ...state.details, formErrors: detailsErrors, wasSubmitted: true },
                landing: { ...state.landing, formErrors: landingErrors, wasSubmitted: true },
            },
            isValid: !detailsErrors && !landingErrors,
        };
    }
    }
}

/** Change the current form to a new one. */
type PhaseAction = {
    type: 'phase';
} & ({
    /** The phases are traversed from first to last (e.g., when creating a new product). The current form is validated if 'continue'. */
    direction: 'back' | 'continue';
} | {
    /** The current form is validated in any case. */
    phase: ProductFormsPhase;
})

function phase(state: ProductFormsState, action: PhaseAction): ProductFormsState {
    if ('phase' in action) {
        if (!state.original)
            // This part is available only when updating an existing product.
            throw new Error('Invalid action for existing product.');

        if (action.phase === state.phase)
            return state;

        const { newState, isValid } = validateCurrentForms(state);

        if (isValid)
            newState.phase = action.phase;

        return newState;
    }

    if (state.original)
        // This part is available only when creating a new product.
        throw new Error('Invalid action for new product.');

    if (action.direction === 'back') {
        // We can only go back from 'checkout' to 'details'. Both landing and thumbnail.
        const phase = state.usecase === NewProductUsecase.Landing ? ProductFormsPhase.LandingDetails : ProductFormsPhase.ThumbnailDetails;
        return { ...state, phase };
    }

    // Just a fix for the thumbnail form. We don't have to do that for the landing form because these two product types are not supported here.
    if (state.phase === ProductFormsPhase.ThumbnailDetails) {
        const thumbnail = state.thumbnail.form;
        // TODO translations.
        // This is not the best solution. However, we just can't afford to make the title not required for all products.
        if (thumbnail.displayType === ProductDisplayType.Button) {
            if (state.type === ProductType.Link)
                thumbnail.title = 'Custom link';
            else if (state.type === ProductType.Referral)
                thumbnail.title = 'Referral link';
        }
    }
    else if (state.phase === ProductFormsPhase.LandingDetails) {
        // The user is creating a new landing page. That means we have to fill in some missing product information from the page.
        const landing = state.landing.form;

        state.thumbnail = {
            ...state.thumbnail,
            form: {
                ...state.thumbnail.form,
                title: landing.title,
                description: '',
                buttonText: landing.buttonText,
                thumbnail: landing.cover,
            },
        };
    }
    else {
    // This should not happen - we can't go forward from 'checkout'.
        throw new Error('Can\'t continue from checkout.');
    }

    // We try to validate the form and then go to the next phase.
    const { newState, isValid } = validateCurrentForms(state);

    if (isValid)
        newState.phase = ProductFormsPhase.Checkout;

    return newState;
}

type ThumbnailFormState = {
    /** Optional. Defaults to {@link ProductDisplayType.Callout} for most types. */
    displayType: ProductDisplayType;
    title: string;
    /** Optional. */
    description: string;
    buttonText: string;
    isHideButton: boolean;
    /** Optional. */
    thumbnail: FileInputValue;
    layout: ProductLayout;
};

function createInitialThumbnailFormState(input: ProductFormsInput, t: TFunction): ThumbnailFormState {
    const { type } = 'product' in input ? input.product : input;
    const product = 'product' in input ? input.product : undefined;
    const tt = createTFunctionWithVariant(t, type);

    const output: ThumbnailFormState = {
        displayType: ProductDisplayType.Callout,
        // TODO translations.
        title: product?.title ?? (type === ProductType.Referral ? 'Set Up Your Own Creator Store' : ''),
        description: product?.description ?? '',
        buttonText: product?.buttonText ?? tt('components:productThumbnailForm.buttonText-placeholder'),
        isHideButton: false,
        thumbnail: product?.thumbnail,
        layout: product?.layout ?? ProductLayout.SmallLeft,
    };

    if (product?.type === ProductType.Link || product?.type === ProductType.Referral) {
        output.displayType = product.displayType;
        output.isHideButton = product.isHideButton ?? false;
    }

    return output;
}

/** Update thumbnail form. */
type ThumbnailAction = {
    type: 'thumbnail';
    field: FormPath<ThumbnailFormState>;
    value: unknown;
};

function thumbnail(state: ProductFormsState, action: ThumbnailAction): ProductFormsState {
    const { form, formErrors } = Updator.update(state.thumbnail, action.field, action.value, state.thumbnail.wasSubmitted ? thumbnailRules : undefined);
    const newState = { ...state, thumbnail: {
        ...state.thumbnail,
        form,
        formErrors,
    } };

    // If there is no thumbnail, we select the default layout. The only exception is products with placeholder thumbnails.
    if (action.field === 'thumbnail' && !action.value && !productsWithPlaceholderThumbnail.includes(state.type))
        form.layout = ProductLayout.SmallLeft;

    return newState;
}

const thumbnailRules: RulesDefinition<ThumbnailFormState> = {
    title: zodRule('common:form-error', zBaseProductUpsert.shape.title),
    buttonText: zodRule('common:form-error', zBaseProductUpsert.shape.buttonText),
    description: zodRule('common:form-error', zBaseProductUpsert.shape.description),
};

type LandingFormState = {
    title: string;
    content: MarkdownInputControl;
    buttonText: string;
    isHideButton: boolean;
    /** Optional. */
    cover: FileInputValue;
};

function createInitialLandingFormState(input: ProductFormsInput): LandingFormState {
    const landing = 'landing' in input ? input.landing : undefined;

    return {
        // TODO translations.
        title: landing?.title ?? 'Get Your [Template/Product/eBook] Today!',
        content: new MarkdownInputControl(landing?.content ?? '', true),
        buttonText: landing?.buttonText ?? 'Buy Now',
        isHideButton: landing?.isHideButton ?? false,
        cover: landing?.cover,
    };
}

/** Update landing form. */
type LandingAction = {
    type: 'landing';
    field: FormPath<LandingFormState>;
    value: unknown;
};

const landingRules: RulesDefinition<LandingFormState> = {
    title: zodRule('common:form-error', zPageUpsert.shape.title),
    buttonText: zodRule('common:form-error', zPageUpsert.shape.buttonText),
    // We don't check the content length here, because it's not that simple - the content is html while the user sees rich text.
};

function landing(state: ProductFormsState, action: LandingAction): ProductFormsState {
    const { form, formErrors } = Updator.update(state.landing, action.field, action.value, state.landing.wasSubmitted ? landingRules : undefined);
    return { ...state, landing: {
        ...state.landing,
        form,
        formErrors,
    } };
}

type DetailsFormState = {
    // Needed for validation.
    readonly type: ProductType;
    visibility: 'public' | 'private';

    // Pricing properties

    currency: CurrencyId;
    /**
     * If undefined, the product is free.
     * The `basePriceInDecimal` and `discountedPriceInDecimal` are named differently from backend to avoid confusion with `price` and `originalPrice`.
     */
    basePriceInDecimal: undefined | '' | number;
    /** The new price after discount. If undefined, there is no discount. */
    discountedPriceInDecimal: undefined | '' | number;
    /** Same for both price and discount. */
    taxRate: TaxRate;
    pricingPeriod: 'monthly' | 'weekly';

    // Specific properties

    sessions: '' | number;
    /** In minutes. */
    duration: '' | number;
    /** Optional. */
    locationId: Id | undefined;
    scheduling: 'enabled' | 'disabled' | 'custom';
    /** Optional unless scheduling is 'custom'. */
    schedulingUrl: string;
    schedulingDays: Record<Weekday, DateRange[]>;
    blockedDates: DateRange[];
    timezone: TimezoneCode;

    digitalType: 'file' | 'url';
    /** Optional. */
    url: string;
    isHideUrl: boolean;
    /** Optional. */
    file: FileInputValue;

    isLimitedOffer: boolean;
};

function createInitialDetailsFormState(input: ProductFormsInput, context: MasterContext): DetailsFormState {
    const { type } = 'product' in input ? input.product : input;
    const product = 'product' in input ? input.product : undefined;
    const usecase = 'usecase' in input ? input.usecase : undefined;
    const isPrivate = (product && !product.isPublic) || usecase === NewProductUsecase.Landing;

    const data: DetailsFormState = {
        // Common fields.
        type,
        visibility: isPrivate ? 'private' : 'public',

        // Pricing fields - handled separately.
        currency: context.teamSettings.currency,
        basePriceInDecimal: undefined,
        discountedPriceInDecimal: undefined,
        taxRate: context.teamSettings.taxRate,

        // Specific fields - need to be set based on the product type.
        pricingPeriod: 'monthly',
        sessions: '',
        duration: '',
        locationId: undefined,
        scheduling: 'disabled',
        schedulingUrl: '',
        schedulingDays: {
            [Weekday.Monday]: [ createDefaultAvailabilityRange() ],
            [Weekday.Tuesday]: [ createDefaultAvailabilityRange() ],
            [Weekday.Wednesday]: [ createDefaultAvailabilityRange() ],
            [Weekday.Thursday]: [ createDefaultAvailabilityRange() ],
            [Weekday.Friday]: [ createDefaultAvailabilityRange() ],
            [Weekday.Saturday]: [],
            [Weekday.Sunday]: [],
        },
        blockedDates: [],
        timezone: DateTime.now().zoneName,
        digitalType: 'url',
        url: '',
        isHideUrl: false,
        file: undefined,
        isLimitedOffer: true,
    };

    if (product) {
        if (product.pricing)
            loadProductPricing(data, product.pricing);

        loadSpecificProductDetails(data, product);
    }

    return data;
}

export function createDefaultAvailabilityRange(): DateRange {
    return {
        start: DateTime.now().set({ hour: 9, minute: 0, second: 0 }),
        end: DateTime.now().set({ hour: 17, minute: 0, second: 0 }),
    };
}

/** Update details form. */
type DetailsAction = {
    type: 'details';
    field: FormPath<DetailsFormState>;
    value: unknown;
};

function details(state: ProductFormsState, action: DetailsAction): ProductFormsState {
    const { form, formErrors } = Updator.update(state.details, action.field, action.value, state.details.wasSubmitted ? detailsRules : undefined);

    return { ...state, details: {
        form,
        formErrors,
        wasSubmitted: state.details.wasSubmitted,
    } };
}

const detailsRules: RulesDefinition<DetailsFormState> = {
    // TODO use zod for this
    basePriceInDecimal: value => value === undefined || transformToPrice(value) !== '' || 'common:form-error.required',
    discountedPriceInDecimal: (value, form) => form.basePriceInDecimal === undefined || value === undefined || transformToPrice(value) !== '' || 'common:form-error.required',
    // TODO use zod for this
    sessions: (value, form) => form.type !== ProductType.Bundle
        || transformToPositiveIntegerOrEmpty(value) !== ''
        || 'common:form-error.required',
    duration: (value, form) => ![ ProductType.Session, ProductType.Bundle ].includes(form.type)
        || transformToPositiveIntegerOrEmpty(value) !== ''
        || 'common:form-error.required',
    url: (value, form) => !isUrlRequired(form) || value !== '' || 'common:form-error.required',
    file: validateFile,
    schedulingUrl: (value, form) => form.scheduling !== 'custom' || value !== '' || 'common:form-error.required',
};

function isUrlRequired(form: DetailsFormState): boolean {
    return form.type === ProductType.Link ||
        ([ ProductType.Digital, ProductType.Lead ].includes(form.type) && form.digitalType === 'url');
}

function validateFile(value: unknown, form: DetailsFormState): string | true {
    const isRequired = [ ProductType.Digital, ProductType.Lead ].includes(form.type) && form.digitalType === 'file';
    if (!isRequired)
        return true;

    const typed = value as FileInputValue;
    if (!typed)
        return 'common:form-error.required';

    if ('id' in typed)
        return true;

    if (typed.sizeMB > MAX_FILE_SIZE_MB)
        return 'components:productDetailsForm.file-too-large-error';

    return true;
}

type CheckoutFormState = {
    successMessage: string;
    /**
     * Doesn't have to be unique now. We will make it unique on backend.
     * This is *not* a valid slug. It's just the user input that needs to be transformed into a slug.
     * Optional. If it's empty, we will use the title.
     */
    slugInit: string;
    // TODO collect info
};

function createInitialCheckoutFormState(input: ProductFormsInput, context: MasterContext, t: TFunction): CheckoutFormState {
    const { type } = 'product' in input ? input.product : input;
    const product = 'product' in input ? input.product : undefined;
    const tt = createTFunctionWithVariant(t, type);

    return {
        successMessage: product?.successMessage ?? tt('components:productCheckoutForm.successMessage-placeholder', { firstName: context.appUser.firstName }),
        slugInit: product?.slug ?? '',
    };
}

/** Update checkout form. */
type CheckoutAction = {
    type: 'checkout';
    field: FormPath<CheckoutFormState>;
    value: unknown;
};

function checkout(state: ProductFormsState, action: CheckoutAction): ProductFormsState {
    const { form, formErrors } = Updator.update(state.checkout, action.field, action.value, state.checkout.wasSubmitted ? checkoutRules : undefined);

    const checkout = {
        ...state.checkout,
        form,
        formErrors,
    };
    if (action.field === 'slugInit')
        checkout.isSlugTouched = true;

    return { ...state, checkout };
}

const checkoutRules: RulesDefinition<CheckoutFormState> = {
    successMessage: zodRule('common:form-error', zBaseProductUpsert.shape.successMessage),
    // We don't validate the slug in the form. If it's empty, we will use the title.
};

type SyncState = {
    upsert: FullProductUpsert;
};

function createProductUpsert(state: ProductFormsState): ProductUpsert {
    const thumbnail = state.thumbnail.form;
    const details = state.details.form;
    const checkout = state.checkout.form;

    const base: BaseProductUpsert = {
        isPublic: details.visibility === 'public',
        layout: thumbnail.layout,
        title: thumbnail.title,
        description: optionalStringToPut(thumbnail.description),
        buttonText: thumbnail.buttonText,
        // If it's button type, we delete the thumbnail (because why not).
        thumbnail: thumbnail.displayType === ProductDisplayType.Button
            ? null
            : fileInputValueToServer(thumbnail.thumbnail),
        // Doesn't matter whether this is enabled or disabled. If it's disabled, it will be corrected on the backend.
        slug: getProductSlug(state, true).final,
        // TODO iteration2
        limit: undefined,
        successMessage: optionalStringToPut(checkout.successMessage),
    };

    return createSpecificProductUpsert(state.type, base, thumbnail, details);
}

export function getProductSlug(state: ProductFormsState, isCustomEnabled: boolean): { suggested: string, final: string } {
    const suggested = state.original
        ? state.original.product.slug
        // If the product is new, the suggested slug is based on the title.
        : createSlug(state.usecase === NewProductUsecase.Landing ? state.landing.form.title : state.thumbnail.form.title);

    // This is the final slug that will be sent to the backend.
    const final = isCustomEnabled
        ? (createSlug(state.checkout.form.slugInit) || suggested)
        : suggested;

    return {
        suggested,
        final,
    };
}

function createSpecificProductUpsert(type: ProductType, base: BaseProductUpsert, thumbnail: ThumbnailFormState, details: DetailsFormState): ProductUpsert {
    const pricing = createProductPricing(details);

    switch (type) {
    case ProductType.Session:
        return {
            type: ProductType.Session,
            ...base,
            pricing,
            sessionsDuration: minutesToSeconds(toNumber(details.duration)),
            locationId: details.locationId,
            schedulingUrl: details.scheduling !== 'custom' ? undefined : details.schedulingUrl,
            availability: details.scheduling !== 'enabled' ? undefined : {
                weekdays: dateRangesToWeekdays(details.schedulingDays),
                blocked: details.blockedDates,
                timezone: details.timezone,
            },
        } satisfies SessionProductUpsert;
    case ProductType.Bundle:
        return {
            type: ProductType.Bundle,
            ...base,
            pricing,
            sessionsCount: toNumber(details.sessions),
            sessionsDuration: minutesToSeconds(toNumber(details.duration)),
            locationId: details.locationId,
            schedulingUrl: details.scheduling !== 'custom' ? undefined : details.schedulingUrl,
            // TODO availability
        } satisfies BundleProductUpsert;
    case ProductType.Digital:
        return {
            type: ProductType.Digital,
            ...base,
            pricing,
            payload: createDigitalPayload(details),
        } satisfies DigitalProductUpsert;
    case ProductType.Lead:
        return {
            type: ProductType.Lead,
            ...base,
            payload: createDigitalPayload(details),
        } satisfies LeadProductUpsert;
    case ProductType.Membership:
        throw new Error(`Unsupported product type "${type}"`);
    case ProductType.Link:
        return {
            type: ProductType.Link,
            ...base,
            url: details.url,
            displayType: thumbnail.displayType,
            isHideUrl: details.isHideUrl,
            isHideButton: thumbnail.isHideButton,
        } satisfies LinkProductUpsert;
    case ProductType.Referral:
        return {
            type: ProductType.Referral,
            ...base,
            displayType: thumbnail.displayType,
            isHideButton: thumbnail.isHideButton,
        } satisfies ReferralProductUpsert;
    case ProductType.Custom:
        return {
            type: ProductType.Custom,
            ...base,
            pricing,
        } satisfies CustomProductUpsert;
    }
}

function createProductPricing(details: DetailsFormState): ProductPricing | undefined {
    if (details.basePriceInDecimal === undefined)
        return undefined;

    return {
        currency: details.currency,
        price: amountFromDecimal(details.discountedPriceInDecimal === undefined ? toNumber(details.basePriceInDecimal) : toNumber(details.discountedPriceInDecimal)),
        originalPrice: details.discountedPriceInDecimal === undefined ? undefined : amountFromDecimal(toNumber(details.basePriceInDecimal)),
        taxRate: details.taxRate,
    };
}

function loadProductPricing(details: DetailsFormState, pricing: ProductPricing): void {
    details.currency = pricing.currency;
    details.basePriceInDecimal = amountToDecimal(pricing.originalPrice ?? pricing.price);
    details.discountedPriceInDecimal = pricing.originalPrice ? amountToDecimal(pricing.price) : undefined;
    details.taxRate = pricing.taxRate;
}

function createDigitalPayload(details: DetailsFormState): DigitalPayloadUpsert {
    if (details.digitalType === 'url')
        return { url: details.url };

    const file = fileInputValueToServer(details.file);
    if (file === null)
        // This should be handled by the validation.
        throw new Error('File is required. This should not happen.');

    return { file };
}

function loadProductPayload(details: DetailsFormState, payload: DigitalPayload): void {
    if ('url' in payload) {
        details.digitalType = 'url';
        details.url = payload.url;
    }
    else {
        details.digitalType = 'file';
        details.file = payload.file;
    }
}

function loadSpecificProductDetails(details: DetailsFormState, product: ProductOutput): void {
    switch (product.type) {
    case ProductType.Session: {
        details.duration = secondsToMinutes(product.sessionsDuration);
        details.locationId = product.location?.id;
        if (product.schedulingUrl) {
            details.scheduling = 'custom';
            details.schedulingUrl = product.schedulingUrl;
        }
        else if (product.availability) {
            details.scheduling = 'enabled';
            // The initial date shouldn't matter since we use it only for the time.
            // There might be some inconsistencies if the date ranges are defined during DST change. But it's an edge case.
            details.schedulingDays = weekdaysToDateRanges(product.availability.weekdays, DateTime.now());
            details.blockedDates = product.availability.blocked;
            details.timezone = product.availability.timezone;
        }
        break;
    }
    case ProductType.Bundle: {
        details.sessions = product.sessionsCount;
        details.duration = secondsToMinutes(product.sessionsDuration);
        details.locationId = product.location?.id;
        if (product.schedulingUrl) {
            details.scheduling = 'custom';
            details.schedulingUrl = product.schedulingUrl;
        }
        // TODO availability
        break;
    }
    case ProductType.Digital: {
        loadProductPayload(details, product.payload);
        break;
    }
    case ProductType.Lead: {
        loadProductPayload(details, product.payload);
        break;
    }
    case ProductType.Membership: {
        throw new Error(`Unsupported product type "${product.type}"`);
    }
    case ProductType.Link: {
        details.url = product.url;
        details.isHideUrl = product.isHideUrl;
        break;
    }
    case ProductType.Referral: {
        // Nothing to do here.
        break;
    }
    case ProductType.Custom: {
        // Nothing to do here.
        break;
    }
    }

    // FIXME Not supported yet.
    // pricingPeriod
    // isLimitedOffer
}

// This function creates only a subset of the product data. It also don't transform all data to the server format (e.g., locations).
export function createProductPreview(type: ProductType, thumbnail: ThumbnailFormState, details: DetailsFormState, locations: LocationOutput[]): ProductPreview {
    const base: ProductPreview = {
        type,
        layout: thumbnail.layout,
        title: thumbnail.title,
        thumbnail: thumbnail.thumbnail,
        description: thumbnail.description,
        buttonText: thumbnail.buttonText,
    };

    const pricing = createProductPricing(details);

    switch (type) {
    case ProductType.Session:
        return {
            ...base,
            pricing,
            sessionsDuration: minutesToSeconds(toNumber(details.duration)),
            location: locations.find(l => l.id === details.locationId),
        };
    case ProductType.Bundle:
        return {
            ...base,
            pricing,
            sessionsCount: toNumber(details.sessions),
            sessionsDuration: minutesToSeconds(toNumber(details.duration)),
            location: locations.find(l => l.id === details.locationId),
        };
    case ProductType.Digital:
        return {
            ...base,
            pricing,
        };
    case ProductType.Lead:
        return base;
    case ProductType.Membership:
        throw new Error(`Unsupported product type "${type}"`);
    case ProductType.Link:
        return {
            ...base,
            url: details.url,
            displayType: thumbnail.displayType,
            isHideUrl: details.isHideUrl,
            isHideButton: thumbnail.isHideButton,
        };
    case ProductType.Referral:
        return {
            ...base,
            displayType: thumbnail.displayType,
            isHideButton: thumbnail.isHideButton,
        };
    case ProductType.Custom:
        return {
            ...base,
            pricing,
        };
    }
}

function createLandingUpsert(state: ProductFormsState): PageUpsert | undefined {
    if (state.original) {
        // If the product already exists, we can't add the landing page.
        if (!state.original.landing)
            return undefined;
    }
    else {
        // If the product is new, it depends on the usecase.
        if (state.usecase !== NewProductUsecase.Landing)
            return undefined;
    }

    const landing = state.landing.form;

    return {
        title: landing.title,
        content: landing.content.getHtml(),
        buttonText: landing.buttonText,
        isHideButton: landing.isHideButton,
        cover: fileInputValueToServer(landing.cover),
    };
}
