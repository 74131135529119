import { Form } from ':components/shadcn';
import { trpc } from ':frontend/context/TrpcProvider';
import type { StoreOutput } from ':utils/entity/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StiggFeature } from ':utils/lib/stigg';
import { UpsellButton } from ':frontend/components/team/subscription';
import { useEntitlement } from ':frontend/lib/stigg';

type HideFlowlanceBrandingProps = Readonly<{
    store: StoreOutput;
    switchSize?: 'small' | 'default';
}>;

export function HideFlowlanceBranding({ switchSize, store }: HideFlowlanceBrandingProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general.hideFlowlanceBranding' });
    const isEnabled = useEntitlement(StiggFeature.RemoveFlowlanceBranding);

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();

    const onChange = useCallback(async () => {
        await updateStoreMutation.mutateAsync({ hideFlowlanceBranding: !store.hideFlowlanceBranding }, {
            onSuccess: data => {
                utils.store.getStore.setData(undefined, data);
            },
        });

    }, [ store, updateStoreMutation, utils.store.getStore ]);

    return (
        <div className='flex flex-wrap items-center justify-between gap-4'>
            <div className='flex items-center gap-2'>
                <Form.Switch
                    label={t('switch-label')}
                    size={switchSize}
                    checked={store.hideFlowlanceBranding}
                    disabled={!isEnabled}
                    onCheckedChange={onChange}
                />
            </div>

            {!isEnabled && (
                <div className='grow flex justify-end'>
                    <UpsellButton feature={StiggFeature.RemoveFlowlanceBranding} />
                </div>
            )}
        </div>
    );
}
