import { type ComponentProps } from 'react';
import { Button, Modal } from ':components/shadcn';
import { type BillingPeriod, Checkout, useStiggContext } from '@stigg/react-sdk';
import { useTranslation } from 'react-i18next';
import { useToggle } from ':frontend/hooks';
import { LockIcon, TriangleWarningIcon } from ':components/icons/basic';
import { getSubscriptionPlanForFeature, PRO_PLAN_ID, StiggFeature } from ':utils/lib/stigg';
import { useMaster, useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { DateTime } from 'luxon';

type UpsellButtonProps = Readonly<{
    /** default: 'Upgrade' */
    text?: string;
    /** button className */
    className?: string;
    /** button size, default 'small' */
    size?: ComponentProps<typeof Button>['size'];
} & ({
    /** Which feature should be enabled by clicking on this button. */
    feature: StiggFeature;
} | {
    /** Disables UpsellModal so you can manage it yourself. */
    onClick: () => void;
})>;

export function UpsellButton({ text, className, size, ...rest }: UpsellButtonProps) {
    const feature = 'feature' in rest ? rest.feature : undefined;
    const onClick = 'feature' in rest ? undefined : rest.onClick;

    const { t } = useTranslation('components', { keyPrefix: 'subscription' });
    const [ showCheckout, setShowCheckout ] = useToggle(false);

    return (<>
        {feature && (
            <SubscriptionCheckoutModal isOpen={showCheckout} onClose={setShowCheckout.false} plan={{ id: getSubscriptionPlanForFeature(feature) }} />
        )}

        <Button variant='secondary' size={size ?? 'small'} onClick={onClick ?? setShowCheckout.true} className={className}>
            <LockIcon />
            {text ?? t('upgrade-button')}
        </Button>
    </>);
}

export type SubscriptionPlan = {
    id: string;
    billingPeriod?: BillingPeriod;
};

type SubscriptionCheckoutModalProps = {
    isOpen: boolean;
    onClose: () => void;
    plan: SubscriptionPlan | undefined;
};

export function SubscriptionCheckoutModal({ isOpen, onClose, plan }: SubscriptionCheckoutModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'subscription' });
    const { refreshData } = useStiggContext();
    const { teamSettings } = useMaster();
    const utils = trpc.useUtils();

    async function onCheckoutCompleted(params: { success: boolean, error?: string }) {
        if (params.success) {
            await utils.team.getSubscription.invalidate();
            await refreshData();
            onClose();
        }
    }

    return (
        <Modal.Root open={isOpen} onOpenChange={open => !open && onClose()}>
            <Modal.Title className='sr-only'>
                {t('subscription-checkout-title')}
            </Modal.Title>

            <Modal.Content closeButton='Close' className='max-w-3xl p-4 [&>*>*]:!mx-0 [&>*>*]:!w-full [&>*>*]:!border-0'>
                <Checkout
                    planId={plan?.id || PRO_PLAN_ID}
                    preferredBillingPeriod={plan?.billingPeriod}
                    onCheckoutCompleted={onCheckoutCompleted}
                    billingCountryCode={teamSettings.country}
                />
            </Modal.Content>
        </Modal.Root>
    );
}

type UpsellBannerProps = Readonly<{
    text: string;
    /** default: 'Upgrade' */
    buttonText?: string;
    /** Which feature should be enabled by clicking on the banner's upsell button. */
    feature: StiggFeature;
}>;

export function UpsellBanner({ text, buttonText, feature }: UpsellBannerProps) {
    return (
        <div className='p-2 w-full flex items-center justify-center'>
            <div className='p-2 flex max-md:flex-col items-center justify-center gap-4 rounded-2xl bg-white text-warning'>
                <div className='pl-3 max-md:pr-3 flex items-center gap-4 text-center'>
                    <TriangleWarningIcon size='md' className='shrink-0' />
                    {text}
                </div>

                <UpsellButton text={buttonText} feature={feature} className='max-md:w-full' />
            </div>
        </div>
    );
}

export function TrialUpsellBanner() {
    const { t } = useTranslation('components', { keyPrefix: 'subscription' });
    const { trialEndDate } = useUser();

    if (!trialEndDate)
        return null;

    // If the trial end date is in the past, it should be undefined because the trial is over. So we can ignore this option.

    return (
        <UpsellBanner
            text={t('trial-banner-text', { endDate: trialEndDate.toLocaleString(DateTime.DATE_MED) })}
            feature={StiggFeature.Store}
        />
    );
}
