import { z } from 'zod';
import { zId } from ':utils/id';
import { zAddressOutput, zAddressUpsert } from './address';
import { zCountryCode, zInvoiceLocaleCode } from ':utils/i18n';
import { zFileOutput, zFileUpsert } from './file';
import { zRawBankAccountData } from './bankAccountData';
import { zCurrencyId, type CurrencyId } from ':utils/money';

// The following enums *have to* be in this file. Or, more precisely, they *mustn't* be in the same file as orders, and this one is the closest one.
// Otherwise, there would be circular dependency.
// Well, *ackchyually*, this is true for only two of them , but one for all, all for one, right?

export enum OrderState {
    fulfilled = 'fulfilled',
    new = 'new',
    overdue = 'overdue',
    canceled = 'canceled',
}

export enum OrderTransition {
    Cancel = 'cancel',
    Fulfill = 'fulfill',
    Unfulfill = 'unfulfill',
    Overdue = 'overdue'
}

export enum PaymentMethod {
    bankTransfer = 'bankTransfer',
    stripe = 'stripe',
    paypal = 'paypal',
    noInvoice = 'noInvoice',
}

export const storePaymentMethods = [ PaymentMethod.stripe, PaymentMethod.paypal ] as const;
export const zStorePaymentMethod = z.enum(storePaymentMethods);
export type StorePaymentMethod = z.infer<typeof zStorePaymentMethod>;

export enum DocumentType {
    Invoice = 'invoice',
    Receipt = 'receipt',
}

// Settings for subscribers (clients)

export type SubscriberSettingsOutput = z.infer<typeof zSubscriberSettingsOutput>;
export const zSubscriberSettingsOutput = z.object({
    id: zId,
    hideEmailOnInvoice: z.boolean(),
    /** This email overrides the client email on the invoice. */
    email: z.string().optional(),
    address: zAddressOutput.optional(),
    cin: z.string().optional(),
    tin: z.string().optional(),
});

export type SubscriberSettingsUpsert = z.infer<typeof zSubscriberSettingsUpsert>;
export const zSubscriberSettingsUpsert = z.object({
    hideEmailOnInvoice: z.boolean(),
    /** This email overrides the client email on the invoice. */
    email: z.string().optional(),
    address: zAddressUpsert.optional(),
    cin: z.string().optional(),
    tin: z.string().optional(),
});

// Settings for suppliers (profiles)

export type InvoicingProfileOutput = z.infer<typeof zInvoicingProfileOutput>;
export const zInvoicingProfileOutput = zSubscriberSettingsOutput.extend({
    title: z.string(),
    locale: zInvoiceLocaleCode,
    dueDays: z.number(),
    legalName: z.string().optional(),
    isLogoCustom: z.boolean(),
    logo: zFileOutput.optional(),
    condensedInvoice: z.boolean(),
    header: z.string().optional(),
    footer: z.string().optional(),
    customKey1: z.string().optional(),
    customValue1: z.string().optional(),
    customKey2: z.string().optional(),
    customValue2: z.string().optional(),
});

/** Like PUT. */
export type InvoicingProfileGeneralUpsert = z.infer<typeof zInvoicingProfileGeneralUpsert>;
const zInvoicingProfileGeneralUpsert = z.object({
    title: z.string(),
    /** This email overrides the client email on the invoice. */
    email: z.string().email().optional(),
    dueDays: z.number(),
    hideEmailOnInvoice: z.boolean(),
    locale: zInvoiceLocaleCode,
    cin: z.string().optional(),
    tin: z.string().optional(),
    legalName: z.string().optional(),
    address: zAddressUpsert.optional(),
});

/** Like PUT. */
export type InvoicingProfileAdvancedUpsert = z.infer<typeof zInvoicingProfileAdvancedUpsert>;
const zInvoicingProfileAdvancedUpsert = z.object({
    isLogoCustom: z.boolean(),
    condensedInvoice: z.boolean(),
    header: z.string().optional(),
    footer: z.string().optional(),
    customKey1: z.string().optional(),
    customValue1: z.string().optional(),
    customKey2: z.string().optional(),
    customValue2: z.string().optional(),
});

/** Like PUT. */
export type InvoicingProfileInit = z.infer<typeof zInvoicingProfileInit>;
export const zInvoicingProfileInit = z.object({
    general: zInvoicingProfileGeneralUpsert,
    advanced: zInvoicingProfileAdvancedUpsert,
    logo: zFileUpsert.optional(),
});

/** Like PATCH. */
export type InvoicingProfileUpdate = z.infer<typeof zInvoicingProfileUpdate>;
export const zInvoicingProfileUpdate = z.object({
    id: zId,
    general: zInvoicingProfileGeneralUpsert.optional(),
    advanced: zInvoicingProfileAdvancedUpsert.optional(),
    logo: zFileUpsert.nullish(),
});

export type InvoicingIdentityUpdate = z.infer<typeof zInvoicingIdentityUpdate>;
export const zInvoicingIdentityUpdate = z.object({
    name: z.string().min(1),
    email: z.string().optional(),
    address: zAddressUpsert.optional(),
    cin: z.string().optional(),
    tin: z.string().optional(),
});

export type InvoicingIdentityOutput = z.infer<typeof zInvoicingIdentityOutput>;
export const zInvoicingIdentityOutput = z.object({
    id: zId,
    name: z.string(),
    email: z.string().optional(),
    address: zAddressOutput.optional(),
    cin: z.string().optional(),
    tin: z.string().optional(),
});

export type InvoicingOverrideOutput = z.infer<typeof zInvoicingOverrideOutput>;
export const zInvoicingOverrideOutput = z.object({
    id: zId,
    condensedInvoice: z.boolean().optional(),
    header: z.string().optional(),
    footer: z.string().optional(),
    customKey1: z.string().optional(),
    customValue1: z.string().optional(),
    customKey2: z.string().optional(),
    customValue2: z.string().optional(),
    locale: zInvoiceLocaleCode.optional(),
});

/**
 * We don't use undefined values because we have to distinguish between undefined (the value shouldn't override the value from the invoicing profile) and an empty string (the value should override, i.e., delete the profile's value).
 * The dueDays is an exception because an invalid number is treated as no override.
 */
export type InvoicingOverrideUpdate = z.infer<typeof zInvoicingOverrideUpdate>;
export const zInvoicingOverrideUpdate = z.object({
    condensedInvoice: z.boolean(),
    header: z.string(),
    footer: z.string(),
    customKey1: z.string(),
    customValue1: z.string(),
    customKey2: z.string(),
    customValue2: z.string(),
    locale: zInvoiceLocaleCode,
    /**
     * The dueDays isn't included in the InvoicingOverrideOutput, because it's stored on the client.
     * However, it's a part of the invoicing overrides (logically), so it's updated with it.
     */
    dueDays: z.number().optional(),
});

export function isCustomKeyValueValid(key: string | undefined, value: string | undefined): boolean {
    const keyOrEmpty = key ?? '';
    const valueOrEmpty = value ?? '';

    return keyOrEmpty === ''
        ? valueOrEmpty === ''
        : valueOrEmpty !== '';
}

export type BankAccountOutput = z.infer<typeof zBankAccountOutput>;
export const zBankAccountOutput = z.object({
    id: zId,
    label: z.string(),
    iban: z.string().optional(),
    swift: z.string().optional(),
    country: zCountryCode,
    raw: zRawBankAccountData,
    currencies: z.array(zCurrencyId),
});

export type BankAccountUpsert = z.infer<typeof zBankAccountUpsert>;
export const zBankAccountUpsert = z.object({
    raw: zRawBankAccountData,
    currencies: z.array(z.string()),
});

export function isCurrencySupported(bankAccounts: BankAccountOutput[], currency: CurrencyId): boolean {
    return bankAccounts.some(account => account.currencies.includes(currency));
}
