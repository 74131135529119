import { env } from ':env';
import qs from 'qs';
import { deepClone } from './common';
import type { Id } from './id';

// TODO use Symbol instead
const FRAGMENT_KEY = '#fragment';
export type Fragment = typeof FRAGMENT_KEY;

export class Route<TParam extends string = never, TQuery extends string = never> {
    constructor(
        /**
         * Used for matching. E.g., `/users/:id`.
         * Contains only the last part, i.e., without any prefixing routers.
         */
        readonly path: string,
        /**
         * On backend, some part of the path is usually taken by express routers, and then the rest is used for the final routing.
         */
        private readonly prefix: string,
        /**
         * The same as prefix but absolute.
         */
        private readonly absolutePrefix: string,
    ) {}

    // TODO what does this return?
    resolve(params: Record<TParam, string>, query?: { [key in TQuery]?: string } ): string {
        return this.innerResolve(this.prefix + this.path, params, query);
    }

    // TODO what does this return?
    get absolutePath(): string {
        return this.absolutePrefix + this.path;
    }

    // TODO what does this return?
    absoluteResolve(params: Record<TParam, string>, query?: { [key in TQuery]?: string }): string {
        return this.innerResolve(this.absolutePrefix + this.path, params, query);
    }

    // TODO what does this return?
    private innerResolve(value: string, params: Record<TParam, string>, query?: { [key in TQuery]?: string }): string {
        // TODO use URL and URLSearchParams
        // https://developer.mozilla.org/en-US/docs/Web/API/URL_API
        for (const key of Object.keys(params))
            value = value.replace(':' + key, params[key as keyof typeof params]);

        if (query) {
            const queryParams = deepClone(query);
            if (FRAGMENT_KEY in queryParams)
                delete queryParams[FRAGMENT_KEY];

            value += qs.stringify(queryParams, { addQueryPrefix: true });

            if (FRAGMENT_KEY in query)
                value += '#' + query[FRAGMENT_KEY];
        }

        return value;
    }
}

export class Router {
    private constructor(
        /**
         * Relative path (join of all parent relative routes).
         */
        readonly prefix: string,
        /**
         * Absolute path (join of all parent absolute routes).
         */
        readonly absolutePrefix: string,
    ) {}

    static root(prefix: string, host: string): Router {
        return new Router(prefix, host + prefix);
    }

    child(childPrefix: string): Router {
        return new Router(this.prefix + childPrefix, this.absolutePrefix + childPrefix);
    }

    /** Copy prefix from another router. */
    copyChild(router: Router): Router {
        return this.child(router.prefix);
    }

    route<TParam extends string = never, TQuery extends string = never>(path: string): Route<TParam, TQuery> {
        return new Route(path, this.prefix, this.absolutePrefix);
    }

    /** Copy path from another route. */
    copyRoute<TParam extends string = never, TQuery extends string = never>(route: Route<TParam, TQuery>): Route<TParam, TQuery> {
        return this.route(route.path);
    }
}


// Not all routes are included here - only those that are needed by frontend. Exclusively backend routes are kept in the backend.

const backendRouter = Router.root('/api', env.VITE_APP_URL);
const authRouter = backendRouter.child('/auth');
const publicRouter = backendRouter.child('/public');
const privateRouter = backendRouter.child('/private');
const trpcRouter = backendRouter.child('/trpc');
const googleRouter = backendRouter.child('/google');

export const routesBE = {
    $router: backendRouter,
    trpc: {
        $router: trpcRouter,
    },
    auth: {
        $router: authRouter,
        login: authRouter.route('/login'),
        registerValidate: authRouter.route('/register-validate'),
        register: authRouter.route('/register'),
        logout: authRouter.route('/logout'),
        refresh: authRouter.route('/refresh'),
        updatePassword: authRouter.route('/update-password'),
        resetPassword: authRouter.route('/reset-password'),
    },
    public: {
        $router: publicRouter,
        version: publicRouter.route('/version'),
        document: publicRouter.route<'id'>('/orders/:id/document'),
        payment: publicRouter.route<'id'>('/orders/:id/pay'),
        file: publicRouter.route<'id'>('/files/:id'),
        visit: publicRouter.route('/visits'),
        /** We deliberately don't use the word "track" to avoid suspicion. */
        track: publicRouter.route('/update'),
    },
    private: {
        $router: privateRouter,
        invoicePreview: privateRouter.route('/orders/preview'),
        ordersExport: privateRouter.route('/orders/export'),
        clientsExport: privateRouter.route('/clients/export'),
        invoicingProfileExample: privateRouter.route<'id'>('/invoicing-profiles/:id/example'),
        uploadFile: privateRouter.route('/files'),
    },
    google: {
        $router: googleRouter,
        oauth: googleRouter.route('/oauth'),
        integration: googleRouter.route('/integration'),
    },
} as const;

// Use inconspicuous name to avoid suspicion. Also, it should be unique because "customer_id" is probably already used.
export const TRACKING_COOKIE_NAME = 'fl_id';

const frontendRouter = Router.root('', env.VITE_APP_URL);

export const routesFE = {
    $router: frontendRouter,
    root: '/',
    dashboard: frontendRouter.route('/'),
    calendar: '/calendar',
    products: {
        list: '/products',
        new: frontendRouter.route<'type'>('/products/new/:type'),
        newPage: frontendRouter.route<'type'>('/products/new-page/:type'),
        detail: frontendRouter.route<'id'>('/products/:id'),
    },
    clients: {
        list: frontendRouter.route('/clients'),
        detail: frontendRouter.route<'id' | 'key', Fragment>('/clients/:id/:key'),
    },
    orders: {
        list: frontendRouter.route('/orders'),
        detail: frontendRouter.route<'id'>('/orders/:id'),
    },
    store: frontendRouter.route<'key'>('/store/:key'),
    directSale: {
        root: frontendRouter.route<'key'>('/direct-sale/:key'),
        product: '/direct-sale/product',
        event: '/direct-sale/event',
        custom: '/direct-sale/custom',
    },
    referral: frontendRouter.route('/referral'),
    team: '/team',
    events: {
        detail: frontendRouter.route<'id'>('/events/:id'),
    },
    settings: frontendRouter.route<'key'>('/settings/:key'),
    payments: frontendRouter.route('/settings/payments'),
    integrations: frontendRouter.route<never, 'refresh-google' | 'error'>('/settings/advanced'),
    auth: {
        login: frontendRouter.route('/login'),
        // This url page is displayed to the users so we use the 'signup' version instead of 'register'.
        claimUrl: frontendRouter.route<never, 'username' | 'ref'>('/signup'),
        register: frontendRouter.route('/create-account'),
        resetPassword: '/reset-password',
    },
    landing: {
        appsumoCode: frontendRouter.route('/appsumo-code'),
    },
    googleError: frontendRouter.route<never, 'error'>('/google'),
    /** Only for development purposes. Not active in production. */
    dev: '/dev',
    /** Not really routes, more like resources accessible from the frontend. */
    files: {
        uploads: (hash: string) => `${frontendRouter.absolutePrefix}/uploads/${hash.slice(0, 2)}/${hash}`,
        static: (fileName: string) => `${frontendRouter.absolutePrefix}/static/${fileName}`,
    },
};

export const schema = env.VITE_APP_URL.startsWith('https://') ? 'https' : 'http';

export const routesStore = {
    // this is pretty weird, but returning '/' could cause double slashes
    store: storeRoute(() => ''),
    product: storeRoute((productSlug: string) => `/${productSlug}`),
    order: storeRoute((orderId: Id) => `/order/${orderId}`),
    terms: storeRoute(() => `/terms`),
    referral: (storeSlug: string) => `https://www.flowlance.com/?ref=${storeSlug}`,
};

// we could probably force the input to be an object for consistency
function storeRoute<TInput extends unknown[]>(path: (...params: TInput) => string) {
    return {
        resolve: ({ domain, slug }: { domain: string, slug: string }, ...params: TInput) => {
            const url = path(...params);
            return `${storeHome(domain, slug, false)}${url}`;
        },
        absoluteResolve: ({ domain, slug }: { domain: string, slug: string }, ...params: TInput) => {
            const url = path(...params);
            return `${storeHome(domain, slug, true)}${url}`;
        },
    };
}

function storeHome(domain: string, slug: string, includeDomain: boolean) {
    if (domain === env.VITE_DEFAULT_STORE_DOMAIN && includeDomain)
        return `${schema}://${domain}/${slug}`;
    if (domain === env.VITE_DEFAULT_STORE_DOMAIN && !includeDomain)
        return `/${slug}`;
    if (domain !== env.VITE_DEFAULT_STORE_DOMAIN && includeDomain)
        return `${schema}://${domain}`;
    if (domain !== env.VITE_DEFAULT_STORE_DOMAIN && !includeDomain)
        // this is pretty weird, but returning '/' would cause double slashes
        return ``;
}

/**
 * Simplifies the url for the users. Only for display purposes.
 * Essentially the opposite of {@link linkify}.
 * However, the use case is completely different - here we want to prettify our system routes. We don't want to do this for users' urls, because the users should decide how they should be displayed.
 */
export function routeToDisplayString(url: string): string {
    return url.replace('https://', '').replace('http://', '');
}

export const PAYMENT_SUCCESS_URL = 'https://flowlance.com/success';
export const PAYMENT_FAIL_URL = 'https://flowlance.com/fail';
