import { routeToDisplayString, routesFE } from ':utils/routes';
import { ProductDisplayType, ProductLayout, productsWithoutCheckout, productsWithPlaceholderThumbnail, ProductType, type LinkProductOutput, type ProductOutput, type ProductPricing, type ReferralProductOutput } from ':utils/entity/product';
import type { IconType } from ':components/icons/common';
import { LinkProductIcon, DigitalProductIcon, LeadProductIcon, MembershipProductIcon, BundleProductIcon, SessionProductIcon, CustomProductIcon, ReferralProductIcon } from ':components/icons/store';
import { ArrowRightIconScaling, CircleHalfDottedClockIcon, CircleMinusIcon, NumberInputIcon } from ':components/icons/basic';
import clsx from 'clsx';
import { forwardRef, type HTMLAttributes, type MouseEvent, type ReactNode } from 'react';
import { getFileInputUrl, MoneyDisplay, type FileInputValue } from ':components/custom';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { simpleLocationIcon } from ':components/icons/location';
import { Button, Card, Skeleton, Tooltip } from ':components/shadcn';
import { linkify } from ':utils/common';
import { secondsToMinutes } from ':utils/dateTime';
import { locationPlatformTranslation, type LocationOutput } from ':utils/entity/location';
import { taxRateIsZero, taxRateLabel, toMoney, type Money, type TaxRate } from ':utils/money';
import { cn } from ':components/shadcn/utils';

// Make sure the PublicDisplay and PhonePreview functions are always in sync.
// The reason is that it's much easier to have two functions than to somehow simulate different screen size.

export type ProductLinkAction = {
    href: string;
    isNewTab?: boolean;
    /** This doesn't work with right click + open in new tab, but that's fine. */
    onNavigate?: (e: MouseEvent) => void;
    /**
     * If present, the button will be disabled and a tooltip about missing payment method will be shown.
     * Only for buttons (i.e., no callout without a button).
     */
    isDisabled?: boolean;
};

type ProductPublicDisplayProps = Readonly<{
    product: ProductOutput;
    linkAction?: ProductLinkAction;
    editComponent?: ReactNode;
}>;

export function ProductPublicDisplay({ product, linkAction, editComponent }: ProductPublicDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });

    if ('displayType' in product && product.displayType === ProductDisplayType.Button)
        return buttonTypePublicDisplay(product, linkAction, editComponent);

    if ('displayType' in product && product.displayType === ProductDisplayType.Callout)
        return calloutTypePublicDisplay(product, false, linkAction, editComponent);

    return defaultPublicDisplay(product, t, false, linkAction, editComponent);
}

export type ProductPreview = ProductWithAttributes & {
    layout: ProductLayout;
    title: string;
    pricing?: ProductPricing;
    thumbnail?: FileInputValue;
    description?: string;
    buttonText: string;
    displayType?: ProductDisplayType;
};

/**
 * Copy of the previous function but only for phones. And with preview data.
 * However, all action buttons or links here don't work (on purpose).
*/
export function ProductPhonePreviewDisplay({ product }: Readonly<{ product: ProductPreview }>) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });

    if ('displayType' in product && product.displayType === ProductDisplayType.Button)
        return buttonTypePublicDisplay(product);

    if ('displayType' in product && product.displayType === ProductDisplayType.Callout)
        return calloutTypePublicDisplay(product, true);

    return defaultPublicDisplay(product, t, true);
}

const layouts = {
    [ProductLayout.SmallLeft]: 'grid gap-4 [&_.fl-thumbnail]:size-12 [&_.fl-thumbnail]:rounded-md [&_.fl-thumbnail]:aspect-square',
    [ProductLayout.Large]: 'grid gap-4 sm:gap-6 [&_.fl-thumbnail]:w-full [&_.fl-thumbnail]:aspect-[2/1]',
    [ProductLayout.MediumLeft]: 'grid grid-cols-2 gap-4 sm:gap-6 [&_.fl-thumbnail]:aspect-square',
    [ProductLayout.MediumRight]: 'grid grid-cols-2 gap-4 sm:gap-6 [&_.fl-thumbnail]:order-1 [&_.fl-thumbnail]:aspect-square',
};

const thumbnailClass = 'fl-thumbnail w-full rounded-lg object-cover';

function getThumbnailUrl(product: ProductOutput | ProductPreview): string | undefined {
    const url = getFileInputUrl(product.thumbnail);
    if (url)
        return url;

    if (productsWithPlaceholderThumbnail.includes(product.type))
        return routesFE.files.static('flowlance-icon.svg');
}

function defaultPublicDisplay(product: ProductOutput | ProductPreview, t: TFunction, isPhonePreview: boolean, linkAction?: ProductLinkAction, editComponent?: ReactNode) {
    const styles = productStyles[product.type];
    const thumbnailUrl = getThumbnailUrl(product);
    const attributes = renderProductAttributes(product, t);

    const isCol = isPhonePreview || product.layout === ProductLayout.MediumLeft || product.layout === ProductLayout.MediumRight;

    return (
        <div className={cn('relative fl-store-font fl-store-card fl-store-shadow bg-white w-full max-w-[600px] p-4 sm:p-6',
            isPhonePreview && 'sm:p-4 sm:gap-4',
            layouts[product.layout],
        )}>
            {thumbnailUrl && (
                <img className={thumbnailClass} src={thumbnailUrl} />
            )}

            {editComponent && (
                <div className='absolute right-4 sm:right-6 top-4 sm:top-6'>
                    {editComponent}
                </div>
            )}

            <div className='min-w-0 flex flex-col gap-4'>
                <div className='flex items-center justify-between gap-2'>
                    <h3 className='min-w-0 flex items-start gap-2 text-2lg/6'>
                        <div className='h-[1lh] flex items-center'>
                            {styles.icon({ size: 22, className: 'shrink-0' })}
                        </div>
                        <span className={clsx(styles.color, 'font-semibold line-clamp-3')}>
                            {product.title}
                        </span>
                    </h3>

                </div>

                {product.description && (
                    <p className='leading-5 whitespace-pre-line break-words'>
                        {product.description}
                    </p>
                )}

                <div className={clsx('flex max-sm:flex-col flex-wrap gap-4 overflow-hidden empty:hidden', isCol ? 'flex-col' : 'sm:gap-2 sm:items-center')}>
                    <ProductPricingBadge product={product} isPhonePreview={isPhonePreview} />

                    {attributes.length > 0 && (<>
                        <div className={clsx('w-full h-px bg-secondary-100', !isCol && 'sm:hidden')} />
                        {...attributes}
                    </>)}

                    <div className='min-w-0 max-w-full grow flex'>
                        {!isCol && (
                            <div className='grow max-sm:hidden' />
                        )}

                        <ActionComponent product={product} linkAction={linkAction} grow={isCol} />
                    </div>
                </div>
            </div>
        </div>
    );
}

type ActionComponentProps = Readonly<{
    product: ProductOutput | ProductPreview;
    linkAction: ProductLinkAction | undefined;
    grow?: boolean;
}>;

function ActionComponent({ product, linkAction, grow }: ActionComponentProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });

    const inner = (
        <Button size='small' className={cn('fl-store-button min-w-0 max-sm:w-full', grow && 'w-full', linkAction?.isDisabled && 'opacity-20 hover:!opacity-30')}>
            <span className='truncate'>
                {product.buttonText}
            </span>
            {productsWithoutCheckout.includes(product.type) && <ArrowRightIconScaling />}
        </Button>
    );

    if (!linkAction)
        return inner;

    if (linkAction.isDisabled) {
        return (
            <Tooltip tooltipText={t('disabled-tooltip')} asChild>
                {inner}
            </Tooltip>
        );
    }

    return (
        <a
            href={linkAction.href}
            {...(linkAction.isNewTab ? { target: '_blank', rel: 'noopener' } : {})}
            onClick={linkAction.onNavigate}
            onAuxClick={linkAction.onNavigate}
            className={clsx('flex max-w-full max-sm:w-full', grow && 'w-full')}
        >
            {inner}
        </a>
    );
}

// Button display type is just special.

function buttonTypePublicDisplay(product: ProductOutput | ProductPreview, linkAction?: ProductLinkAction, editComponent?: ReactNode) {
    return (
        <div className='relative w-full max-w-[600px]'>
            <ActionComponent product={product} linkAction={linkAction} grow />

            {editComponent && (
                // No need to resize since the edit component isn't in phone preview anyway.
                <div className={clsx('absolute right-4 sm:right-6 top-0 bottom-0 flex items-center')}>
                    {editComponent}
                </div>
            )}
        </div>
    );
}

// Callout type is also a bit unique.

function calloutTypePublicDisplay(product: LinkProductOutput | ReferralProductOutput | ProductPreview, isPhonePreview?: boolean, linkAction?: ProductLinkAction, editComponent?: ReactNode) {
    const styles = product.type !== ProductType.Referral ? productStyles[product.type] : undefined;
    const thumbnailUrl = getThumbnailUrl(product);

    // We don't show the url for referral products, because it's not important.
    const isShowUrl = product.type !== ProductType.Referral && !product.isHideUrl;
    const isShowButton = !product.isHideButton;

    const components = (isShowUrl ? 1 : 0) + (isShowButton ? 1 : 0) + (product.description ? 1 : 0);

    const isCol = isPhonePreview || product.layout === ProductLayout.MediumLeft || product.layout === ProductLayout.MediumRight;

    const inner = (
        <div className={cn('relative fl-store-font fl-store-card fl-store-shadow bg-white w-full max-w-[600px] p-4 sm:p-6',
            isPhonePreview && 'sm:p-4 sm:gap-4',
            // Mimic the link hover effect.
            linkAction && components === 0 && 'cursor-pointer hover:opacity-90',
            layouts[product.layout],
        )}>
            {thumbnailUrl && product.layout !== ProductLayout.SmallLeft && (
                <img className={thumbnailClass} src={thumbnailUrl} />
            )}

            {editComponent && (
                <div className='absolute right-4 sm:right-6 top-4 sm:top-6'>
                    {editComponent}
                </div>
            )}

            <div className={cn('min-w-0 flex max-sm:flex-col flex-wrap sm:items-center justify-end gap-y-4 gap-x-4',
                isCol && 'flex-col sm:items-start justify-normal gap-y-3',
                isPhonePreview && 'gap-y-4',
            )}>
                <div className={cn('max-w-full grow flex items-center gap-4 max-sm:mb-auto',
                    components === 0 && 'w-full justify-center',
                    components >= 2 && 'w-full',
                )}>
                    {thumbnailUrl && product.layout === ProductLayout.SmallLeft && (
                        <img className={thumbnailClass} src={thumbnailUrl} />
                    )}

                    <h3 className='min-w-0 flex items-start gap-2'>
                        {styles?.icon({ size: 22, className: 'shrink-0' })}
                        <span className={clsx(styles?.color, 'text-lg/5 font-semibold line-clamp-3')}>
                            {product.title}
                        </span>
                    </h3>
                </div>

                {product.description && (
                    <p className='w-full leading-5 whitespace-pre-line break-words'>
                        {product.description}
                    </p>
                )}

                {isShowUrl && (
                    <ProductLink key='url' type={product.type} url={product.url!} className={clsx('max-sm:w-full mr-auto', isCol && 'w-full')} onNavigate={linkAction?.onNavigate} />
                )}

                {isShowButton && (
                    <ActionComponent product={product} linkAction={linkAction} grow={isCol} />
                )}
            </div>
        </div>
    );

    if (!linkAction || components !== 0)
        return inner;

    return (
        <a
            href={linkAction.href}
            {...(linkAction.isNewTab ? { target: '_blank', rel: 'noopener' } : {})}
            onClick={linkAction.onNavigate}
            onAuxClick={linkAction.onNavigate}
            className='w-full max-w-[600px] hover:opacity-90'
        >
            {inner}
        </a>
    );
}

// Inner display in our app.

type ProductDirectSaleDisplayProps = HTMLAttributes<HTMLDivElement> & Readonly<{
    product: ProductOutput;
    referralLink?: string;
    topRight?: ReactNode;
    bottomRight?: ReactNode;
}>;

export const ProductDisplay = forwardRef<HTMLDivElement, ProductDirectSaleDisplayProps>(({ product, referralLink, topRight, bottomRight, className, ...props }, ref) => {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = renderProductAttributes(product, t, referralLink);

    return (
        <Card className={cn('w-full h-[250px] rounded-2xl flex flex-col gap-4', className)} ref={ref} {...props}>
            <div className='w-full flex items-center justify-between'>
                <h3 className='min-w-0 flex items-start gap-2 text-2lg/6'>
                    <div className='h-[1lh] flex items-center'>
                        {styles.icon({ size: 22, className: 'shrink-0' })}
                    </div>
                    <span className={clsx(styles.color, 'font-semibold truncate')}>
                        {product.title}
                    </span>
                </h3>
                {topRight}
            </div>

            {attributes.length > 0 && (<>
                <div className='w-full h-px bg-secondary-100' />
                {...attributes}
            </>)}

            <div className='grow -mt-4' />

            <div className='h-9 flex items-center w-full'>
                <ProductPricingBadge product={product} />
                <div className='grow' />
                {bottomRight}
            </div>
        </Card>
    );
});

export function ProductDirectSaleSkeleton() {
    return <Skeleton className='w-full h-[250px]' />;
}

type ProductForInvoiceItem = ProductWithAttributes & {
    title: string;
};

type ProductInvoiceItemDisplayProps = Readonly<{
    product: ProductForInvoiceItem;
    onRemove?: () => void;
    price: Money | undefined;
    taxRate: TaxRate;
}>;

export function ProductInvoiceItemDisplay({ product, onRemove, price, taxRate }: ProductInvoiceItemDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = renderProductAttributes(product, t);

    return (
        <Card className='flex items-center gap-4'>
            <div className='grow min-w-0 space-y-4'>
                <div className='max-md:space-y-2 md:flex md:items-center md:justify-between md:gap-4'>
                    <h3 className='min-w-0 w-full flex items-center gap-2'>
                        {styles.icon({ size: 22, className: 'shrink-0' })}
                        <div className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                            {product.title}
                        </div>
                    </h3>

                    <ProductPricingLabel type={product.type} price={price} taxRate={taxRate} />
                </div>

                {attributes.length > 0 && (<>
                    <div className='w-full h-px bg-secondary-100' />

                    <div className='max-sm:space-y-2 sm:h-4 sm:flex sm:items-center sm:gap-4'>
                    {...attributes}
                    </div>
                </>)}
            </div>

            {onRemove && (
                <Button variant='transparent' size='exact' className='shrink-0' onClick={onRemove} aria-label={t('remove-button')}>
                    <CircleMinusIcon />
                </Button>
            )}
        </Card>
    );
}

export function ProductCheckoutItemDisplay({ product }: Readonly<{ product: ProductOutput }>) {
    const styles = productStyles[product.type];

    return (
        <div className='w-full bg-white border p-6 rounded-2xl shadow-[0px_5px_15px_0px_rgba(0,0,0,0.05)] max-md:space-y-2 md:flex md:items-center md:justify-between md:gap-4'>
            <h3 className='min-w-0 w-full flex items-center gap-2'>
                {styles.icon({ size: 22, className: 'shrink-0' })}
                <div className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                    {product.title}
                </div>
            </h3>

            <ProductPricingLabel type={product.type} pricing={product.pricing} />
        </div>
    );
}

export function ProductSelectOption({ data: { value: product } }: Readonly<{ data: { value: ProductOutput }} >) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = renderProductAttributes(product, t);

    return (
        // There's already some padding from the default option component.
        <div className='grow overflow-hidden py-2 rounded'>
            <div className='flex items-center justify-between gap-4'>
                <div className='min-w-0 flex items-center justify-between gap-2'>
                    {styles.icon({ size: 22, className: 'shrink-0' })}
                    <span className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                        {product.title}
                    </span>
                </div>
                <ProductPricingLabel type={product.type} pricing={product.pricing} />
            </div>
            {attributes.length > 0 && (
                <div className='mt-1 h-4 flex items-center gap-3'>
                {...attributes}
                </div>
            )}
        </div>
    );
}

type ProductPricingBadgeProps = Readonly<{
    product: ProductOutput | ProductPreview;
    isPhonePreview?: boolean;
    className?: string;
}>;

export function ProductPricingBadge({ product, isPhonePreview, className }: ProductPricingBadgeProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const { pricing, type } = product;

    if (productsWithoutCheckout.includes(type))
        return null;

    const isCompact = product.layout === ProductLayout.MediumLeft || product.layout === ProductLayout.MediumRight;
    const outerClassName = cn(
        'w-fit flex items-start gap-x-2 py-2 px-4 rounded-lg border',
        productStyles[type].border,
        isCompact && 'max-sm:flex-col-reverse',
        isCompact && isPhonePreview && 'flex-col-reverse',
        className,
    );

    if (!pricing) {
        return (
            <div className={outerClassName}>
                <div className='text-secondary-600'>{t('free-price')}</div>
            </div>
        );
    }

    const { price, originalPrice, currency } = pricing;

    // TODO membership - use /mo for month, /wk for week.
    // /m., /t. should be ok for cz. Or just use the english variant?

    return (
        <div className={outerClassName}>
            {originalPrice && (
                <div className='text-secondary line-through'>
                    &nbsp;<MoneyDisplay amount={originalPrice} currency={currency} className='text-secondary-400' />&nbsp;
                </div>
            )}

            <MoneyDisplay amount={price} currency={currency} />
        </div>
    );
}

type ProductPricingLabelProps = Readonly<{
    type: ProductType;
    className?: string;
} & ({
    pricing: ProductPricing | undefined;
} | {
    price: Money | undefined;
    taxRate: TaxRate;
})>;

function ProductPricingLabel({ type, className, ...rest }: ProductPricingLabelProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });

    if (productsWithoutCheckout.includes(type))
        return null;

    let price: Money | undefined;
    let taxRate!: TaxRate;

    if ('price' in rest) {
        price = rest.price;
        taxRate = rest.taxRate;
    }
    else if (rest.pricing) {
        price = toMoney(rest.pricing.price, rest.pricing.currency);
        taxRate = rest.pricing.taxRate;
    }

    if (!price || price.amount === 0) {
        return (
            <div className={className}>
                <div className='font-semibold'>{t('free-price')}</div>
            </div>
        );
    }

    return (
        <div className={clsx('flex items-center', className)}>
            {!taxRateIsZero(taxRate) && (
                <div className='mr-9 font-semibold text-nowrap'>{taxRateLabel(taxRate)}</div>
            )}
            <MoneyDisplay money={price} />
        </div>
    );
}

type ProductWithAttributes = {
    type: ProductType;
    sessionsCount?: number;
    /** In seconds. */
    sessionsDuration?: number;
    location?: LocationOutput;
    url?: string;
    isHideUrl?: boolean;
    isHideButton?: boolean;
    displayType?: ProductDisplayType;
    // TODO add more
};

export function renderProductAttributes(product: ProductWithAttributes, t: TFunction, referralLink?: string): ReactNode[] {
    const styles = productStyles[product.type];
    const common: { icon: IconType, text: string }[] = [];

    if (product.sessionsCount)
        common.push({ icon: NumberInputIcon, text: t('sessionsCount', { count: product.sessionsCount }) });
    if (product.sessionsDuration)
        common.push({ icon: CircleHalfDottedClockIcon, text: t(product.type === ProductType.Session ? 'duration' : 'duration-per-session', { count: secondsToMinutes(product.sessionsDuration) }) });
    if (product.location)
        common.push({ icon: simpleLocationIcon(product.location), text: locationPlatformTranslation(product.location, t) });

    // TODO Digital product stuff (but not url, that's private).

    const output: ReactNode[] = common.map(({ icon, text }, i) => (
        <div key={i} className='min-w-0 max-w-full flex gap-1 items-center'>
            {icon({ size: 'sm', className: clsx('shrink-0', styles.iconColor) })}
            <span className='truncate'>{text}</span>
        </div>
    ));

    // Link product shows url (that's public, because there's no price). Digital product doesn't, because its url is private.
    if (!product.isHideUrl && product.url && product.type === ProductType.Link) {
        output.push(
            <ProductLink key='url' type={product.type} url={product.url} />,
        );
    }

    if (product.type === ProductType.Referral && referralLink) {
        output.push(
            <ProductLink key='url' type={product.type} url={referralLink} />,
        );
    }

    return output;
}

type ProductLinkProps = Readonly<{
    type: ProductType;
    url: string;
    className?: string;
    /** {@link ProductLinkAction} */
    onNavigate?: (e: MouseEvent) => void;
}>;

function ProductLink({ type, url, className, onNavigate }: ProductLinkProps) {
    // If the site isn't indexed by google, it won't show here. So that might be a wake-up call for our users.
    // However, we should probably add some warning.

    // This component is also used during product creation, in which case there would be an empty box (on firefox).
    const trimmedUrl = url.trim();

    return (
        <a
            href={linkify(url)}
            target='_blank'
            rel='noopener'
            onClick={onNavigate}
            onAuxClick={onNavigate}
            className={clsx('max-w-full flex items-center justify-center gap-1 py-2 px-4 rounded-full border hover:underline', productStyles[type].border, className)}
        >
            {type !== ProductType.Referral && !!trimmedUrl && (
                <img src={`https://www.google.com/s2/favicons?domain=${trimmedUrl}`} alt='' className='shrink-0 size-4' />
            )}
            <div className='min-h-4 truncate'>{routeToDisplayString(url)}</div>
        </a>
    );
}

export const productStyles: {
    [key in ProductType]: {
        icon: IconType;
        iconColor: string;
        color: string;
        bg: string;
        border: string;
    };
} = {
    [ProductType.Session]: { icon: SessionProductIcon, iconColor: 'text-[#c48f00]', color: 'text-[#6b4e00]', bg: 'bg-[#ffeab3]', border: 'border-[#e7d299]' },
    [ProductType.Bundle]: { icon: BundleProductIcon, iconColor: 'text-[#b200cf]', color: 'text-[#5c006b]', bg: 'bg-[#f3d9f8]', border: 'border-[#e099ec]' },
    [ProductType.Digital]: { icon: DigitalProductIcon, iconColor: 'text-[#00cf5f]', color: 'text-[#006b31]', bg: 'bg-[#d9f8e7]', border: 'border-[#99ecbf]' },
    [ProductType.Lead]: { icon: LeadProductIcon, iconColor: 'text-[#4b4da3]', color: 'text-[#31326b]', bg: 'bg-[#e4e4f1]', border: 'border-[#b7b8da]' },
    [ProductType.Membership]: { icon: MembershipProductIcon, iconColor: 'text-[#96031a]', color: 'text-[#6b0213]', bg: 'bg-[#dfb3ba]', border: 'border-[#d59aa3]' },
    [ProductType.Link]: { icon: LinkProductIcon, iconColor: 'text-[#009c80]', color: 'text-[#006956]', bg: 'bg-[#b2e1d9]', border: 'border-[#99d7cc]' },
    [ProductType.Referral]: { icon: ReferralProductIcon, iconColor: 'text-[#b45700]', color: 'text-[#b45700]', bg: 'bg-[#f3e5d8]', border: 'border-[#b45700]' },
    [ProductType.Custom]: { icon: CustomProductIcon, iconColor: 'text-[#b20baa]', color: 'text-[#690664]', bg: 'bg-[#f3d9f8]', border: 'border-[#e09ddd]' },
};
